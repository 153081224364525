import React, { useEffect, useRef, useState } from 'react'
import hometopimg from '../Images/Home/iPhone 15 Pro.webp'
import { Link, useNavigate } from 'react-router-dom'
import ellips1 from '../Images/Home/Ellipse 3963.webp'
import ellips2 from '../Images/Home/Ellipse 3964.webp'
import ellips3 from '../Images/Home/Ellipse 3965.webp'
import ellips4 from '../Images/Home/Ellipse 3966.webp'
import optimizetask from '../Images/Home/Group 1000007771.webp'
import mobile1 from '../Images/Home/signup.webp'
import mobile2 from '../Images/Home/mandirate.webp'
import mobile3 from '../Images/Home/community.webp'
import mobile4 from '../Images/Home/IOt mock.png'
import ourProduct from '../Images/Home/Our Products PNG.webp'
import ourProductnew from '../Images/Home/Our Products.webp'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import YouTubePlayer from '../Components/YouTubePlayer'
import YouTubePlayersmall from '../Components/YouTubePlayersmall'
import modelimg from '../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp'
import { Card, Modal, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import { IOTproduct, faqData, initialImages } from '../Constants/Homedata';
import iosqr from '../Images/Home/iosqr.webp'
import qrforandroid from '../Images/Home/qr_forandroid.png'
import { blogdata } from '../Constants/Blogdata';
import Iotproduct from '../Components/Iotproduct'
import Seo from '../Components/Seo'
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
import { Animated } from 'react-animated-css'
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import "odometer/themes/odometer-theme-default.css";
import Demoform from '../Components/Demoform'
import { bloglist } from '../Redux/GetblogSlice'
import { useDispatch, useSelector } from 'react-redux'
import { videolist } from '../Redux/GetvideoSlice';
import review1 from '../Images/Home/review1.png';
import review2 from '../Images/Home/review2.png';
import review3 from '../Images/Home/review3.png';
import review4 from '../Images/Home/review4.png';
import Demoformhome from '../Components/Demoformhome'

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef2 = useRef(null);
    const sliderRef3 = useRef(null);
    const [mainImage, setMainImage] = useState(mobile1);
    const [currentWord, setCurrentWord] = useState('Farming');
    const [hoverEffect, setHoverEffect] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [hoveredCard, setHoveredCard] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredExplore, setIsHoveredExplore] = useState(false);
    const [odometerValue, setOdometerValue] = useState(0);

    const videolists = useSelector((state) => state.video.data.results);
    const bloglists = useSelector((state) => state.blog.data.results);
    // console.log(bloglists)
    const dispatch = useDispatch();
    const Videodata = videolists || [];
    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);
                await dispatch(videolist());
                await dispatch(bloglist());
            } finally {
                // setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        setTimeout(() => {
            setOdometerValue(5);
        }, 1000);
    }, []);
    const handleHover = () => {
        setIsHovered(!isHovered);
    };


    const handleHoverExplore = (index) => {
        setIsHoveredExplore(!isHoveredExplore);
        setHoveredCard(index);
    };

    useEffect(() => {
        const changeWord = () => {
            const words = ['Farming', 'Harvest', 'Cultivation'];
            const currentIndex = words.indexOf(currentWord);
            const nextIndex = (currentIndex + 1) % words.length;
            setCurrentWord(words[nextIndex]);
        };
        const intervalId = setInterval(changeWord, 1000);
        return () => clearInterval(intervalId);
    }, [currentWord]);

    const changeImage = (imageType) => {
        switch (imageType) {
            case 'signup':
                setMainImage(mobile1);
                break;
            case 'explore':
                setMainImage(mobile2);
                break;
            case 'find':
                setMainImage(mobile3);
                break;
            case 'seamless':
                setMainImage(mobile4);
                break;
            default:
                break;
        }
        setHoverEffect(true);
    };

    const resetImage = () => {
        setMainImage(mobile2);
        setHoverEffect(false);
    };

    const goToPreviousSlide = () => {
        if (sliderRef2.current) {
            sliderRef2.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef2.current) {
            sliderRef2.current.slickNext();
        }
    };

    const goToPreviousSlide3 = () => {
        if (sliderRef3.current) {
            sliderRef3.current.slickPrev();
        }
    };

    const goToNextSlide3 = () => {
        if (sliderRef3.current) {
            sliderRef3.current.slickNext();
        }
    };
    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 440,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const sliderSettings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };


    const sliderSettings3 = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 1.5,
                },
            },
        ],
    };

    const [openIndex, setOpenIndex] = useState(null);
    const toggleAnswer1 = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Carousel
    const [images, setImages] = useState(initialImages);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            moveItems();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const moveItems = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(nextIndex);
    };

    const handleItemClick = (index) => {
        setCurrentIndex(index);
    };


    const getCircularIndex = (index, length) => {
        return (index + length) % length;
    };
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };
    const handleModalClose1 = () => {
        setShowModal1(false);
        // setShowApplicationForm(false);
    };
    const handleModalOpen = () => {
        setShowModal(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };
    const handleModalOpen1 = () => {
        setShowModal1(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };

    const handleDownloadClick = () => {

        if (navigator.userAgentData.mobile) {
            if (navigator.userAgentData.platform.includes('Android')) {
                window.open('https://play.google.com/store/apps/details?id=com.rashailagro.fasalam', '_blank');
            } else {
                window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
            }
        } else {
            handleModalOpen1();
        }
    };


    const handleDownloadClick_ios = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };

    const handleDownloadClick_android = () => {
        window.open('https://play.google.com/store/apps/details?id=com.rashailagro.fasalam', '_blank');
    };

    const [productName, setproductName] = useState('Home enquiry');
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const navigate = useNavigate();
    const toComponentB = (data) => {
        navigate(`/blog/${encodeURIComponent(data.slug)}`, { state: { data } });
    };


    function truncateHtml(html, maxLength) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const textContent = doc.body.textContent || '';
        return textContent.length > maxLength
            ? `${textContent.substring(0, 40)}...`
            : textContent;
    }


    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Rashail Agro",
        "url": "https://rashailagro.in/",
        "logo": "https://rashailagro.in/static/media/Rashail%20Agro%20LOGO%20(1).2d847f5d699fbc1530f8.png",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+918349503619",
            "contactType": "customer service",
            "availableLanguage": ["English", "Hindi"]
        },
        "sameAs": [
            "https://www.facebook.com/rashailagro/",
            "https://twitter.com/RashailAgro",
            "https://www.instagram.com/rashailagro/",
            "https://www.youtube.com/channel/UCiyVvtJtp-MPyYWgDBHXKRw",
            "https://www.linkedin.com/company/rashail-agro/",
            "https://maps.app.goo.gl/nWyW12LQayJrfyVc9",
            "https://medium.com/@rashailagro",
            "https://www.reddit.com/user/rashailagro/"
        ]
    };


    useEffect(() => {
        const handleClick = (event) => {
            const rect = event.target.getBoundingClientRect();
            const scaleX = event.target.naturalWidth / rect.width;
            const scaleY = event.target.naturalHeight / rect.height;
            const x = (event.clientX - rect.left) * scaleX;
            const y = (event.clientY - rect.top) * scaleY;

            // Define your mapping areas here
            if (isInsideCircle(x, y, 300, 650, 100)) {
                window.location.href = "/shop/phase-preventer-mini/";
            } else if (isInsideCircle(x, y, 500, 400, 100)) {
                window.location.href = "/shop/farm-weather-station/";
            } else if (isInsideCircle(x, y, 880, 410, 100)) {
                window.location.href = "/shop/farm-iot-data-logger/";
            } else if (isInsideCircle(x, y, 1020, 700, 100)) {
                window.location.href = "/shop/gsm-motor-controller-mobile-starter/";
            } else if (isInsideCircle(x, y, 700, 660, 100)) {
                window.location.href = "/shop/rapid-soil-tester/";
            }
        };

        const handleMouseMove = (event) => {
            const rect = event.target.getBoundingClientRect();
            const scaleX = event.target.naturalWidth / rect.width;
            const scaleY = event.target.naturalHeight / rect.height;
            const x = (event.clientX - rect.left) * scaleX;
            const y = (event.clientY - rect.top) * scaleY;

            // Change cursor to pointer if inside the circular area
            if (isInsideCircle(x, y, 300, 650, 100) || isInsideCircle(x, y, 500, 400, 100) || isInsideCircle(x, y, 880, 410, 100) || isInsideCircle(x, y, 1020, 700, 100) || isInsideCircle(x, y, 700, 660, 100)) {
                event.target.style.cursor = 'pointer';
            } else {
                event.target.style.cursor = 'default';
            }
        };

        const isInsideCircle = (x, y, centerX, centerY, radius) => {
            return Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2) <= radius;
        };

        const image = document.getElementById("productImage");
        image.addEventListener("click", handleClick);
        image.addEventListener("mousemove", handleMouseMove);

        return () => {
            image.removeEventListener("click", handleClick);
            image.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);


    return (
        <div className='container-fluid mb-15'>
            {/* <Helmet>
                <title>Smart Farming Solutions by Rashail Agro</title>
                <meta name="description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
                <base target="_blank" href="https://rashailagro.in/" />
                <link rel="canonical" href="https://rashailagro.in/" />
                <meta name="keywords" content="smart farming, precision farming, new technologies in agriculture, iot in agriculture, internet of things in agriculture, farming in india, farming app, farming and agriculture, farmer tools, farmer in agriculture, farm equipment, artificial intelligence in agriculture, ai in agriculture, agriculture technology, agriculture startups in india, agriculture in iot, agriculture in india, agriculture farming, agriculture equipment machine, agriculture equipment list, agriculture equipment, agriculture department, agriculture app, agriculture and technology, agricultural services, agricultural products, agricultural machinery, agriculture, agricalchar farm, agri tech, agri startups in india, crop management solution, iot smart agriculture" />
                <meta property="og:title" content="Smart Farming Solutions by Rashail Agro" />
                <meta property="og:description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
                <meta property="og:url" content="https://rashailagro.in/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Smart Farming Solutions by Rashail Agro" />
                <meta name="twitter:description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
                <meta property="twitter:url" content="https://rashailagro.in/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>Smart Farming Solutions by Rashail Agro</Title>
                <Meta name="description" content="Grow more crops with Rashail Agro's Fasalam farming app, IoT Products and Data-driven Insights. We are India's leading AgriTech firm, simplifying smart farming." />
                <base target="_blank" href="https://rashailagro.in/" />
                <HeadLink rel="canonical" content="https://rashailagro.in/" />
                <Meta name="keywords" content="smart farming, precision farming, new technologies in agriculture, iot in agriculture, internet of things in agriculture, farming in india, farming app, farming and agriculture, farmer tools, farmer in agriculture, farm equipment, artificial intelligence in agriculture, ai in agriculture, agriculture technology, agriculture startups in india, agriculture in iot, agriculture in india, agriculture farming, agriculture equipment machine, agriculture equipment list, agriculture equipment, agriculture department, agriculture app, agriculture and technology, agricultural services, agricultural products, agricultural machinery, agriculture, agricalchar farm, agri tech, agri startups in india, crop management solution, iot smart agriculture" />
                <Meta property="og:title" content="Smart Farming Solutions by Rashail Agro" />
                <Meta property="og:description" content="Grow more crops with Rashail Agro's Fasalam farming app, IoT Products and Data-driven Insights. We are India's leading AgriTech firm, simplifying smart farming." />
                <Meta property="og:url" content="https://rashailagro.in/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Smart Farming Solutions by Rashail Agro" />
                <Meta name="twitter:description" content="Grow more crops with Rashail Agro's Fasalam farming app, IoT Products and Data-driven Insights. We are India's leading AgriTech firm, simplifying smart farming." />
                <Meta property="twitter:url" content="https://rashailagro.in/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row justify-content-center  mx-auto mt-4 ">
                <div className="col-md-10 ">
                    <h1 className='mt-3 text-start heading_home'>Where Technology meets <span className='heading_home_color'>{currentWord}</span></h1>
                </div>
            </div>
            <div className="row justify-content-center text-center mx-auto mb-0">
                <div className="col-md-5 col-sm-12  order-lg-1 " >
                    <p className='homeheading_fer text-center text-md-start'>
                        Further your agricultural might with state-of-the-art IoT technology and AI. We help you grow more produce- Fresher, Sweeter, and Greener!
                    </p>
                    <div className="mt-4  mb-md-2 mb-md-0 ">
                        <div className="d-md-flex">
                            <button
                                className="btn buttonfunctionaity mb-md-2 mb-2"
                                onMouseEnter={handleHoverExplore}
                                onMouseLeave={handleHoverExplore}
                                // onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}
                                onClick={handleModalOpen}
                            >
                                {isHoveredExplore ? (
                                    <>
                                        Request a Demo <FontAwesomeIcon icon={faArrowRight} />
                                    </>
                                ) : (
                                    'Request a Demo'
                                )}
                            </button>

                            <Link to="#">
                                <button
                                    className="btn buttonfunctionaity1 mx-md-2 mb-2"
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHover}
                                    // onClick={handleModalOpen}
                                    onClick={handleDownloadClick}
                                >
                                    {isHovered ? (
                                        <>
                                            Download Fasalam App <FontAwesomeIcon icon={faDownload} />
                                        </>
                                    ) : (
                                        'Download Fasalam App'
                                    )}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row  flex-md-nowrap">
                        <div className="col-md-4 d-none d-md-block">
                            <div className="d-flex mt-5 mb-5 mb-md-0 position-relative">
                                <img src={review1} alt="fasalam-user-1" className="img-fluid" style={{ position: 'absolute', left: '0', width: '50px', height: '50px', borderRadius: "50%" }} />
                                <img src={review2} alt="fasalam-user-2" className="img-fluid" style={{ position: 'absolute', left: '25px', width: '50px', height: '50px', borderRadius: "50%" }} />
                                <img src={review3} alt="fasalam-user-3" className="img-fluid" style={{ position: 'absolute', left: '50px', width: '50px', height: '50px', borderRadius: "50%" }} />
                                <img src={review4} alt="fasalam-user-4" className="img-fluid " style={{ position: 'absolute', left: '75px', width: '50px', height: '50px', borderRadius: "50%" }} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 86 86" fill="none" className="img-fluid" style={{ position: 'absolute', left: '100px' }}>
                                    <circle cx="41" cy="41" r="41" fill="#D9D9D9" stroke="#FBFBFB" strokeWidth="3" />

                                    {/* Plus sign */}
                                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="40" fill="#9FAFA4">
                                        +
                                    </text>
                                </svg>
                            </div>
                        </div>

                        <div className="col-md-8 d-none d-md-block mt-3 ">
                            <div className='text-start mt-4  mb-md-0 margin_activeuser'>
                                <span className='user_2m ' >
                                    <Odometer
                                        value={odometerValue}

                                    />k+
                                    <p className='user_active'>Active Users</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 order-lg-2 " >
                    <img src={hometopimg} alt="Fasalam_farming_app" className="img-fluid" style={{ width: '350px', height: 'auto' }} />
                </div>
            </div>
            <div className="row middle_down_farming justify-content-center text-center mt-2 mb-5 position-relative">
                {/* <div style={{ position: 'absolute', top: '-30px', left: '0' }} >
                    <label className="switch" onMouseEnter={handleHoverToggle} onMouseLeave={handleMouseLeaveToggle}>
                        <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
                        <span className="slider round"></span>
                    </label>
                </div> */}
                <div className="col-md-5 ">
                    <h2 className='mt-3 text-center text-md-start download_farming_home'>Download your Free App Now!</h2>
                    <p className='text-center text-md-start enhance_home'>
                        Fasalam is free and always will be. Download your farming companion and discover how innovation is reshaping farming.
                    </p>
                </div>
                <div className="col-md-5 d-flex ">
                    <div className="card mx-2" style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                        <div className="card-body">
                            <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For iOS</h3>
                            <button className="btn buttonfunctionaity_farming btn-blocks mb-2" onClick={handleDownloadClick}>Download app</button>
                            <div className='mt-md-4 mx-md-3 text-start'>
                                <img src={iosqr} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                            </div>
                            <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                    <g transform="translate(30, 30)">
                                        <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1747 7.79248C28.7402 5.77168 29.9269 2.91548 29.4977 0C26.9391 0.177808 23.9485 1.81479 22.2035 3.94849C20.6128 5.88179 19.3054 8.75777 19.816 11.5491C22.6131 11.6366 25.4999 9.96004 27.1747 7.79248ZM41 35.2172C39.8806 37.7122 39.342 38.827 37.9 41.0369C35.8885 44.1217 33.0522 47.963 29.5341 47.9912C26.4117 48.0251 25.6065 45.945 21.3674 45.9704C17.1284 45.993 16.2447 48.0307 13.1166 47.9996C9.60139 47.9686 6.91377 44.5027 4.90226 41.4179C-0.725479 32.7985 -1.31743 22.6804 2.15291 17.2982C4.62171 13.4767 8.51568 11.2414 12.174 11.2414C15.8968 11.2414 18.2394 13.2961 21.3226 13.2961C24.3132 13.2961 26.1339 11.2358 30.4403 11.2358C33.7002 11.2358 37.1537 13.0223 39.6113 16.1043C31.554 20.5467 32.8586 32.1211 41 35.2172Z" fill="#FBFBFB" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="card mx-2 " style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                        <div className="card-body">
                            <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For Android</h3>
                            <button className="btn buttonfunctionaity_farming btn-blocks mb-2"
                                onClick={handleDownloadClick_android} >Download app</button>
                            <div className='mt-md-4 mx-md-3 text-start '>
                                <img src={qrforandroid} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                            </div>
                            <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                    <g transform="translate(30, 30)">
                                        <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                        <g clip-path="url(#clip0_1617_5839)">
                                            <path d="M6.66272 15.548C5.83593 15.548 5.13341 15.8362 4.55669 16.4135C3.97997 16.9905 3.69141 17.6827 3.69141 18.4903V30.8949C3.69141 31.7213 3.97987 32.4232 4.55669 33.0002C5.13341 33.5773 5.83552 33.8653 6.66241 33.8653C7.48951 33.8653 8.18657 33.5772 8.75379 33.0002C9.32142 32.4232 9.60503 31.7213 9.60503 30.8949V18.4903C9.60503 17.6824 9.31637 16.9902 8.73965 16.4135C8.16282 15.8365 7.47021 15.548 6.66272 15.548Z" fill="#FBFBFB" />
                                            <path d="M10.7598 35.3083C10.7598 36.1927 11.0675 36.9427 11.6828 37.5583C12.2983 38.1731 13.0481 38.4807 13.9329 38.4807H16.0677L16.0968 45.029C16.0968 45.8561 16.3852 46.5576 16.9621 47.1347C17.5388 47.7117 18.2313 48.0001 19.0389 48.0001C19.8662 48.0001 20.5679 47.7117 21.1449 47.1347C21.7219 46.5576 22.0102 45.8561 22.0102 45.029V38.4807H25.9908V45.029C25.9908 45.8561 26.2791 46.5576 26.8559 47.1347C27.4333 47.7117 28.1352 48.0001 28.9623 48.0001C29.7887 48.0001 30.4906 47.7117 31.0673 47.1347C31.644 46.5576 31.9321 45.8561 31.9321 45.029V38.4807H34.0959C34.9608 38.4807 35.7014 38.1737 36.3167 37.5583C36.9322 36.9427 37.24 36.1927 37.24 35.3083V16.0961H10.7598V35.3083Z" fill="#FBFBFB" />
                                            <path d="M30.5477 4.41346L32.5953 0.634356C32.7302 0.384389 32.6817 0.192115 32.4514 0.057634C32.201 -0.0578519 32.0088 -0.000159481 31.8747 0.230812L29.7973 4.03841C27.9704 3.23072 26.0382 2.82687 23.9994 2.82687C21.9611 2.82687 20.0282 3.23072 18.2012 4.03841L16.1242 0.230913C15.9901 -5.84507e-05 15.7975 -0.0577509 15.5475 0.057735C15.3166 0.192216 15.2689 0.38449 15.4034 0.634457L17.4516 4.41346C15.3743 5.47102 13.721 6.94708 12.4898 8.84133C11.2591 10.7357 10.6436 12.808 10.6436 15.0577H37.3271C37.3271 12.808 36.7116 10.7356 35.4808 8.84133C34.2495 6.94708 32.6061 5.47092 30.5477 4.41346ZM18.706 9.90849C18.4851 10.1298 18.2208 10.2403 17.913 10.2403C17.6052 10.2403 17.3458 10.1299 17.134 9.90849C16.9227 9.68752 16.8167 9.42301 16.8167 9.11535C16.8167 8.80759 16.9227 8.54307 17.134 8.3218C17.3455 8.10052 17.6053 7.99019 17.913 7.99019C18.2204 7.99019 18.4848 8.10042 18.706 8.3218C18.9273 8.54307 19.0379 8.80759 19.0379 9.11535C19.0379 9.42311 18.9273 9.68722 18.706 9.90849ZM30.8654 9.90849C30.6534 10.1298 30.3938 10.2403 30.0861 10.2403C29.7783 10.2403 29.5145 10.1299 29.2932 9.90849C29.0719 9.68752 28.9617 9.42301 28.9617 9.11535C28.9617 8.80759 29.0719 8.54307 29.2932 8.3218C29.5144 8.10052 29.7783 7.99019 30.0861 7.99019C30.3938 7.99019 30.6534 8.10042 30.8654 8.3218C31.0764 8.54307 31.1826 8.80759 31.1826 9.11535C31.1826 9.42311 31.0767 9.68722 30.8654 9.90849Z" fill="#FBFBFB" />
                                            <path d="M43.4424 16.3989C42.8656 15.8317 42.1637 15.548 41.3366 15.548C40.5293 15.548 39.8369 15.8317 39.2602 16.3989C38.6832 16.9663 38.3945 17.6636 38.3945 18.4903V30.8949C38.3945 31.7213 38.6832 32.4232 39.2602 33.0002C39.8369 33.5773 40.5293 33.8653 41.3366 33.8653C42.1637 33.8653 42.8656 33.5772 43.4424 33.0002C44.0198 32.4232 44.3081 31.7213 44.3081 30.8949V18.4903C44.3081 17.6635 44.0198 16.9662 43.4424 16.3989Z" fill="#FBFBFB" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for large view ---------- */}
            <div className="row mt-3 justify-content-center text-center mx-auto  mb-5 d-none d-md-flex d-md-block">
                <h3><span className='line_heading'></span>Your Gateway to Smart Farming</h3>
                <h2 className='heading_home_color'><span className='heading_home'>Fasalam</span>  Farming App</h2><br />
                <div className="col-md-5 mt-md-3">
                    {/* <img src={mobile1} alt="Landingimg" className="img-fluid"  /> */}
                    <img
                        src={mainImage}
                        className={`img-fluid ${currentSlide === 0 ? 'leftToRight' : ''} ${hoverEffect ? 'image-hover' : ''}`}
                        alt="fasalam-mockups"
                        style={{ width: '400px', height: '750px' }}
                    />
                </div>
                <div className="col-md-5 mt-md-3">
                    <div className='text-start' onMouseOver={() => changeImage('signup')} onMouseOut={resetImage}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" >
                                {/* <circle cx="40" cy="40" r="40" fill="white" /> */}
                                <g filter="url(#filter0_d_1909_3081)">
                                    <circle cx="40" cy="40" r="40" fill="white" />
                                </g>
                                <g transform="translate(18, 18)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60742 20.9999C3.60742 21.7203 4.1914 22.3043 4.91177 22.3043H23.9946L20.5846 25.227C20.0377 25.6957 19.9744 26.5192 20.4432 27.0662C20.9119 27.6131 21.7354 27.6764 22.2824 27.2076L28.3693 21.9902C28.6584 21.7425 28.8248 21.3806 28.8248 20.9999C28.8248 20.6192 28.6584 20.2573 28.3693 20.0097L22.2824 14.7922C21.7354 14.3234 20.9119 14.3867 20.4432 14.9337C19.9744 15.4806 20.0377 16.3041 20.5846 16.7729L23.9946 19.6956H4.91177C4.1914 19.6956 3.60742 20.2796 3.60742 20.9999Z" fill="#2FB95D" />
                                    <path d="M17.0859 17.087H17.7438C17.2942 15.8112 17.5192 14.3369 18.4628 13.2361C19.8692 11.5952 22.3395 11.4052 23.9804 12.8116L30.0673 18.029C30.9346 18.7725 31.4338 19.8577 31.4338 20.9999C31.4338 22.1424 30.9346 23.2276 30.0673 23.9711L23.9804 29.1885C22.3395 30.5949 19.8692 30.4048 18.4628 28.7639C17.5192 27.6631 17.2942 26.1888 17.7438 24.913H17.0859V27.9565C17.0859 32.8754 17.0859 35.3349 18.6141 36.8631C20.1423 38.3913 22.6018 38.3913 27.5207 38.3913H29.2598C34.1788 38.3913 36.6383 38.3913 38.1665 36.8631C39.6946 35.3349 39.6946 32.8754 39.6946 27.9565V14.0434C39.6946 9.12443 39.6946 6.66492 38.1665 5.13678C36.6383 3.60864 34.1788 3.60864 29.2598 3.60864H27.5207C22.6018 3.60864 20.1423 3.60864 18.6141 5.13678C17.0859 6.66492 17.0859 9.12442 17.0859 14.0434V17.087Z" fill="#2FB95D" />
                                </g>
                            </svg>
                        </span>
                        <span className='sub_heading_home mx-4 leftToRightAnimation' onMouseOver={() => changeImage('signup')} onMouseOut={resetImage}>Sign up</span>
                        <p className='paraheading'>Sign Up for your Fasalam account today and cultivate a world of possibilities with us!</p>
                    </div>
                    <div className='text-start' onMouseOver={() => changeImage('explore')} onMouseOut={resetImage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                            <g filter="url(#filter0_d_1909_3081)">
                                <circle cx="47" cy="48" r="40" fill="white" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="1" />
                                    <feGaussianBlur stdDeviation="4" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                </filter>
                            </defs>
                            <g transform="translate(28, 28)">
                                <g clip-path="url(#clip0_1909_3082)">
                                    <path d="M39.0881 37.9596H38.2357V16.9683H38.235C37.1878 16.9683 36.1963 16.6265 35.3713 16.051C34.8157 16.4417 34.1762 16.7304 33.4688 16.8714C33.1442 16.9361 32.8143 16.9683 32.4876 16.9683C32.2711 16.9683 32.0593 16.9442 31.8489 16.9168V32.5314C31.8489 33.4224 31.1262 34.145 30.2345 34.145H24.435V29.7226L26.4797 33.1351C26.7211 33.5386 27.1487 33.7621 27.5874 33.7621C27.8123 33.7621 28.0412 33.7029 28.249 33.5785C28.8595 33.2121 29.0582 32.4206 28.6916 31.8094L24.5814 24.9489C24.327 24.5232 23.8649 24.3129 23.4023 24.339C23.3713 24.3362 18.6401 24.3355 18.6112 24.3376C18.1423 24.3053 17.6734 24.5177 17.4149 24.9489L13.3046 31.8094C12.9381 32.4206 13.1368 33.2121 13.7474 33.5785C13.9551 33.7029 14.184 33.7621 14.4089 33.7621C14.8476 33.7621 15.2753 33.5386 15.5166 33.1351L17.5744 29.7007V34.145H11.7625C10.8707 34.145 10.1481 33.4225 10.1481 32.5314V16.9168C9.93782 16.9442 9.72594 16.9683 9.50938 16.9683C9.18484 16.9683 8.85475 16.9361 8.52484 16.8707C7.81796 16.7298 7.17988 16.4417 6.625 16.051C5.80001 16.6265 4.80923 16.9683 3.76195 16.9683H3.76126V37.9596H2.90879C1.79288 37.9596 0.888672 38.8638 0.888672 39.9798C0.888672 41.0957 1.79288 41.9999 2.90879 41.9999H39.0884C40.2044 41.9999 41.1085 41.0957 41.1085 39.9798C41.1083 38.8638 40.2041 37.9596 39.0881 37.9596Z" fill="#2FB95D" />
                                    <path d="M20.9976 17.0916C19.5144 17.0916 18.3105 18.4674 18.3105 20.1657C18.3105 21.8626 19.5144 23.2398 20.9976 23.2398C22.4821 23.2398 23.6853 21.8626 23.6853 20.1657C23.6852 18.4674 22.4821 17.0916 20.9976 17.0916Z" fill="#2FB95D" />
                                    <path d="M2.94018 14.6934C3.21463 14.7745 3.49099 14.8136 3.76397 14.8136C5.00016 14.8136 6.14303 14.0085 6.51488 12.7627L6.6661 12.2573C6.80155 13.4682 7.68851 14.5077 8.9468 14.758C9.13581 14.7958 9.3249 14.8136 9.51061 14.8136C10.8534 14.8136 12.0532 13.8677 12.3255 12.5014L12.3956 12.1501C12.4946 13.5176 13.5576 14.659 14.9684 14.7993C15.0655 14.8088 15.1617 14.813 15.2573 14.813C16.715 14.813 17.9642 13.7074 18.1121 12.225L18.1334 12.0119C18.1726 13.5651 19.4371 14.813 20.9992 14.813C22.5613 14.813 23.8258 13.5651 23.865 12.0119L23.8862 12.225C24.0341 13.7074 25.2834 14.813 26.7411 14.813C26.8367 14.813 26.9329 14.8089 27.0299 14.7993C28.4407 14.659 29.5044 13.5176 29.6027 12.1501L29.6728 12.5014C29.9452 13.8683 31.1449 14.8136 32.4876 14.8136C32.6734 14.8136 32.8625 14.7958 33.0515 14.758C34.3098 14.5077 35.1975 13.4682 35.3322 12.2573L35.4834 12.7627C35.8553 14.0086 36.9981 14.8136 38.2343 14.8136C38.5072 14.8136 38.7836 14.7744 39.0581 14.6934C40.5789 14.2387 41.4425 12.6381 40.9888 11.1187L38.3958 2.44016C37.9634 0.992164 36.6316 0 35.1204 0H6.8779C5.36664 0 4.03486 0.992164 3.60243 2.44016L1.0095 11.1187C0.555748 12.6381 1.4194 14.2387 2.94018 14.6934Z" fill="#2FB95D" />
                                </g>
                            </g>
                        </svg>
                        <span className='sub_heading_home leftToRightAnimation' onMouseOver={() => changeImage('explore')} onMouseOut={resetImage}>Explore Mandi Rates</span>
                        <p className='paraheading'>Explore <b>Current Mandi Rates</b> to make informed decisions for your agricultural endeavors.</p>
                    </div>
                    <div className='text-start' onMouseOver={() => changeImage('find')} onMouseOut={resetImage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                            <g filter="url(#filter0_d_1909_3081)">
                                <circle cx="47" cy="48" r="40" fill="white" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="1" />
                                    <feGaussianBlur stdDeviation="4" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                </filter>
                            </defs>
                            <g transform="translate(28, 28)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                    <path d="M9.625 32.375V27.125C9.62159 25.3258 10.0468 23.5518 10.8654 21.9497C11.6841 20.3476 12.8726 18.9635 14.3325 17.9121C13.3479 16.7588 12.682 15.3682 12.4008 13.8781C12.1196 12.3881 12.2329 10.8504 12.7295 9.41759C11.4555 8.73795 9.97052 8.5689 8.57636 8.9448C7.1822 9.3207 5.98348 10.2133 5.22381 11.4413C4.46415 12.6693 4.20055 14.1404 4.48659 15.5557C4.77263 16.9711 5.58684 18.2244 6.76375 19.061C5.27608 19.7057 4.0091 20.771 3.11842 22.1258C2.22773 23.4806 1.75212 25.0661 1.75 26.6875V30.625C1.75139 31.7849 2.21277 32.8969 3.03294 33.717C3.85311 34.5372 4.9651 34.9986 6.125 35H10.2244C9.83101 34.1807 9.6262 33.2838 9.625 32.375Z" fill="#2FB95D" />
                                    <path d="M35.2367 19.061C36.4136 18.2244 37.2278 16.9711 37.5139 15.5557C37.7999 14.1404 37.5363 12.6693 36.7767 11.4413C36.017 10.2133 34.8183 9.3207 33.4241 8.9448C32.0299 8.5689 30.545 8.73795 29.271 9.41759C29.7675 10.8504 29.8808 12.3881 29.5996 13.8781C29.3184 15.3682 28.6525 16.7588 27.668 17.9121C29.1279 18.9635 30.3164 20.3476 31.135 21.9497C31.9537 23.5518 32.3789 25.3258 32.3755 27.125V32.375C32.3743 33.2838 32.1695 34.1807 31.7761 35H35.8755C37.0354 34.9986 38.1474 34.5372 38.9675 33.717C39.7877 32.8969 40.2491 31.7849 40.2505 30.625V26.6875C40.2483 25.0661 39.7727 23.4806 38.882 22.1258C37.9914 20.771 36.7244 19.7057 35.2367 19.061Z" fill="#2FB95D" />
                                    <path d="M24.6374 18.2192C25.9423 17.4256 26.9518 16.227 27.512 14.8062C28.0723 13.3854 28.1525 11.8204 27.7404 10.3497C27.3283 8.8791 26.4465 7.5836 25.2296 6.66078C24.0126 5.73796 22.5273 5.23846 21 5.23846C19.4727 5.23846 17.9874 5.73796 16.7704 6.66078C15.5535 7.5836 14.6717 8.8791 14.2596 10.3497C13.8475 11.8204 13.9277 13.3854 14.488 14.8062C15.0482 16.227 16.0577 17.4256 17.3626 18.2192C15.5939 18.9426 14.0803 20.1755 13.0141 21.7613C11.9479 23.3471 11.3774 25.2141 11.375 27.125V32.375C11.3764 33.5349 11.8378 34.6469 12.6579 35.467C13.4781 36.2872 14.5901 36.7486 15.75 36.75H26.25C27.4099 36.7486 28.5219 36.2872 29.3421 35.467C30.1622 34.6469 30.6236 33.5349 30.625 32.375V27.125C30.6226 25.2141 30.0521 23.3471 28.9859 21.7613C27.9197 20.1755 26.4061 18.9426 24.6374 18.2192Z" fill="#2FB95D" />
                                </svg>
                            </g>
                        </svg>
                        <span className='sub_heading_home' onMouseOver={() => changeImage('find')} onMouseOut={resetImage}>Join Our Farming Community</span>
                        <p className='paraheading'>Connect with like-minded farmers and become a part of our thriving <b> Farming Community</b> where knowledge is shared.</p>
                    </div>
                    <div className='text-start' onMouseOver={() => changeImage('seamless')} onMouseOut={resetImage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                            <g filter="url(#filter0_d_1909_3081)">
                                <circle cx="47" cy="48" r="40" fill="white" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="1" />
                                    <feGaussianBlur stdDeviation="4" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                </filter>
                            </defs>
                            <g transform="translate(28, 28)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                    <path d="M30.2798 22.9097V39.375C30.2798 39.549 30.2107 39.7159 30.0876 39.839C29.9646 39.9621 29.7977 40.0312 29.6236 40.0312H7.12733C6.95329 40.0312 6.78641 39.9621 6.6633 39.839C6.54024 39.7159 6.47111 39.549 6.47111 39.375V22.9097C6.46803 22.8901 6.46803 22.8702 6.47111 22.8506C6.46796 22.8113 6.46796 22.7718 6.47111 22.7325C6.48293 22.6887 6.50059 22.6468 6.52361 22.6078C6.5394 22.5749 6.55924 22.544 6.58264 22.5159C6.61014 22.4763 6.64335 22.4409 6.68108 22.4109C6.69257 22.3962 6.70576 22.383 6.72046 22.3716L17.9686 14.4966C18.0784 14.4203 18.209 14.3795 18.3426 14.3795C18.4764 14.3795 18.6069 14.4203 18.7167 14.4966L29.9648 22.3716C29.9796 22.383 29.9928 22.3962 30.0042 22.4109C30.042 22.4409 30.0751 22.4763 30.1026 22.5159C30.1261 22.544 30.146 22.5749 30.1617 22.6078C30.1847 22.6468 30.2024 22.6887 30.2142 22.7325C30.2168 22.7696 30.2168 22.8069 30.2142 22.8441C30.2395 22.8622 30.2617 22.8844 30.2798 22.9097Z" fill="#2FB95D" />
                                    <path d="M23.2122 27.9695V40.0314H13.5391V27.9695C13.5391 27.7954 13.6082 27.6285 13.7313 27.5054C13.8543 27.3824 14.0213 27.3132 14.1953 27.3132H22.5559C22.73 27.3132 22.8969 27.3824 23.02 27.5054C23.143 27.6285 23.2122 27.7954 23.2122 27.9695Z" fill="white" />
                                    <path d="M28.0742 1.96875C27.9002 1.96875 27.7333 2.03789 27.6102 2.16096C27.4871 2.28403 27.418 2.45095 27.418 2.625C27.418 2.79905 27.4871 2.96597 27.6102 3.08904C27.7333 3.21211 27.9002 3.28125 28.0742 3.28125C30.9027 3.2934 33.611 4.42597 35.6061 6.43089C37.6011 8.43583 38.7204 11.1497 38.7186 13.9781C38.7186 14.1522 38.7878 14.3191 38.9108 14.4422C39.0339 14.5652 39.2008 14.6344 39.3748 14.6344C39.5489 14.6344 39.7158 14.5652 39.8389 14.4422C39.962 14.3191 40.0311 14.1522 40.0311 13.9781C40.0346 10.8011 38.7777 7.75235 36.536 5.50091C34.2945 3.24947 31.2513 1.97918 28.0742 1.96875Z" fill="#2FB95D" />
                                    <path d="M28.0742 5.14505C27.9002 5.14505 27.7333 5.21419 27.6102 5.33726C27.4871 5.46033 27.418 5.62725 27.418 5.8013C27.418 5.97534 27.4871 6.14227 27.6102 6.26533C27.7333 6.38841 27.9002 6.45755 28.0742 6.45755C30.0608 6.46796 31.9622 7.26533 33.362 8.67496C34.7619 10.0846 35.5459 11.9916 35.5423 13.9782C35.5423 14.1522 35.6115 14.3192 35.7346 14.4422C35.8577 14.5653 36.0246 14.6344 36.1986 14.6344C36.3726 14.6344 36.5396 14.5653 36.6626 14.4422C36.7857 14.3192 36.8548 14.1522 36.8548 13.9782C36.8584 11.6435 35.936 9.40268 34.2901 7.74689C32.6441 6.09111 30.4089 5.15547 28.0742 5.14505Z" fill="#2FB95D" />
                                    <path d="M28.0801 8.3147C27.906 8.3147 27.7392 8.38387 27.616 8.50691C27.493 8.63003 27.4238 8.79691 27.4238 8.97095C27.4238 9.14498 27.493 9.31193 27.616 9.43498C27.7392 9.55809 27.906 9.6272 28.0801 9.6272C29.226 9.63763 30.3212 10.101 31.1266 10.9162C31.932 11.7315 32.3821 12.8322 32.3785 13.9781C32.3785 14.1522 32.4477 14.3191 32.5707 14.4422C32.6938 14.5653 32.8607 14.6344 33.0348 14.6344C33.2089 14.6344 33.3758 14.5653 33.4988 14.4422C33.6219 14.3191 33.691 14.1522 33.691 13.9781C33.6946 12.4841 33.1062 11.0495 32.0547 9.98813C31.0031 8.92678 29.5741 8.32513 28.0801 8.3147Z" fill="#2FB95D" />
                                    <path d="M28.0534 11.4976C27.5661 11.4963 27.0894 11.6397 26.6838 11.9096C26.2781 12.1795 25.9616 12.5638 25.7745 13.0137C25.5875 13.4637 25.5381 13.959 25.6329 14.437C25.7277 14.915 25.9622 15.3541 26.3067 15.6987C26.6513 16.0432 27.0904 16.2778 27.5684 16.3725C28.0464 16.4672 28.5417 16.4179 28.9917 16.2309C29.4416 16.0438 29.8259 15.7274 30.0958 15.3217C30.3657 14.916 30.5091 14.4393 30.5078 13.9519C30.5061 13.3015 30.2469 12.6783 29.787 12.2184C29.3271 11.7585 28.7038 11.4993 28.0534 11.4976Z" fill="#2FB95D" />
                                    <path d="M9.96877 13.5976C9.58112 13.2187 9.06051 13.0067 8.51845 13.0069C8.2465 13.0077 7.97737 13.0626 7.72675 13.1682C7.4762 13.2739 7.249 13.4283 7.05856 13.6224C6.86805 13.8165 6.71803 14.0466 6.61716 14.2991C6.51632 14.5517 6.46662 14.8219 6.47097 15.0938V19.8844C6.47097 19.8844 6.47097 19.9304 6.47097 19.9501C6.47847 20.0379 6.50537 20.1231 6.54972 20.1994C6.54972 20.1994 6.54972 20.2454 6.54972 20.2585L6.58251 20.2913C6.62931 20.3459 6.68469 20.3924 6.74658 20.4291L6.8122 20.4751C6.89824 20.5167 6.99228 20.5391 7.08783 20.5407C7.16651 20.5392 7.24434 20.5237 7.31751 20.4947L7.38314 20.4619C7.41123 20.4528 7.43774 20.4395 7.46189 20.4226L10.251 18.4538C10.2657 18.4424 10.2789 18.4291 10.2903 18.4144C10.3243 18.3848 10.355 18.3518 10.3822 18.316L10.4544 18.2176C10.4734 18.1803 10.4866 18.1405 10.4938 18.0994C10.5005 18.0559 10.5005 18.0116 10.4938 17.9682C10.4967 17.9508 10.4967 17.933 10.4938 17.9157V15.0938C10.5232 14.5453 10.3344 14.0074 9.96877 13.5976Z" fill="#2FB95D" />
                                    <path d="M33.9672 22.1025L18.7488 11.4253C18.6389 11.3491 18.5085 11.3083 18.3747 11.3083C18.241 11.3083 18.1105 11.3491 18.0007 11.4253L2.78224 22.1025C2.57713 22.246 2.40229 22.4285 2.26771 22.6396C2.13312 22.8506 2.04143 23.0861 1.99786 23.3326C1.90986 23.8304 2.02323 24.3428 2.31302 24.757C2.60281 25.1713 3.04528 25.4534 3.54311 25.5414C4.04093 25.6294 4.55331 25.516 4.96755 25.2262L18.3747 15.8156L31.7819 25.2262C32.1961 25.516 32.7085 25.6294 33.2064 25.5414C33.7042 25.4534 34.1466 25.1713 34.4364 24.757C34.7262 24.3428 34.8396 23.8304 34.7516 23.3326C34.6636 22.8348 34.3814 22.3923 33.9672 22.1025Z" fill="#2FB95D" />
                                </svg>
                            </g>
                        </svg>
                        <span className='sub_heading_home' onMouseOver={() => changeImage('seamless')} onMouseOut={resetImage}>Seamless IoT Farm experience</span>
                        <p className='paraheading'>Experience the future of agriculture with our seamless <b>IoT Farming Solutions</b>. </p>
                    </div>

                </div>
            </div>
            {/* for mobile view ---------- */}
            <div className="row justify-content-center text-center mx-auto  mb-5 d-block d-md-none">
                <div className="col-md-10 text-center">
                    <h3><span className='line_heading'></span>Your Gateway to Smart Farming</h3>
                    <h2 className='heading_home_color'><span className='heading_home'>Fasalam</span>  Farming App</h2><br />
                    <Slider {...sliderSettings2}>
                        <div className='justify-content-center text-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" >
                                    <circle cx="40" cy="40" r="40" fill="#043A3A" />
                                    <g transform="translate(18, 18)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60742 20.9999C3.60742 21.7203 4.1914 22.3043 4.91177 22.3043H23.9946L20.5846 25.227C20.0377 25.6957 19.9744 26.5192 20.4432 27.0662C20.9119 27.6131 21.7354 27.6764 22.2824 27.2076L28.3693 21.9902C28.6584 21.7425 28.8248 21.3806 28.8248 20.9999C28.8248 20.6192 28.6584 20.2573 28.3693 20.0097L22.2824 14.7922C21.7354 14.3234 20.9119 14.3867 20.4432 14.9337C19.9744 15.4806 20.0377 16.3041 20.5846 16.7729L23.9946 19.6956H4.91177C4.1914 19.6956 3.60742 20.2796 3.60742 20.9999Z" fill="#2FB95D" />
                                        <path d="M17.0859 17.087H17.7438C17.2942 15.8112 17.5192 14.3369 18.4628 13.2361C19.8692 11.5952 22.3395 11.4052 23.9804 12.8116L30.0673 18.029C30.9346 18.7725 31.4338 19.8577 31.4338 20.9999C31.4338 22.1424 30.9346 23.2276 30.0673 23.9711L23.9804 29.1885C22.3395 30.5949 19.8692 30.4048 18.4628 28.7639C17.5192 27.6631 17.2942 26.1888 17.7438 24.913H17.0859V27.9565C17.0859 32.8754 17.0859 35.3349 18.6141 36.8631C20.1423 38.3913 22.6018 38.3913 27.5207 38.3913H29.2598C34.1788 38.3913 36.6383 38.3913 38.1665 36.8631C39.6946 35.3349 39.6946 32.8754 39.6946 27.9565V14.0434C39.6946 9.12443 39.6946 6.66492 38.1665 5.13678C36.6383 3.60864 34.1788 3.60864 29.2598 3.60864H27.5207C22.6018 3.60864 20.1423 3.60864 18.6141 5.13678C17.0859 6.66492 17.0859 9.12442 17.0859 14.0434V17.087Z" fill="#2FB95D" />
                                    </g>
                                </svg>
                            </div>
                            <span className='sub_heading_home mx-4 leftToRightAnimation'>Sign up</span>
                            <p className='paraheading'><b>Sign Up</b> for your account today and cultivate a world of possibilities with us!</p>
                            <div className="container d-flex justify-content-center align-items-center " >
                                <img
                                    src={mobile1}
                                    className='img effortlessmobil img-fluid'
                                    alt="Main"
                                    style={{ maxWidth: '178px', maxHeight: '360px' }}
                                />
                            </div>
                        </div>
                        <div className='justify-content-center text-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                                    <g filter="url(#filter0_d_1909_3081)">
                                        <circle cx="47" cy="48" r="40" fill="white" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="1" />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                        </filter>
                                    </defs>
                                    <g transform="translate(28, 28)">
                                        <g clip-path="url(#clip0_1909_3082)">
                                            <path d="M39.0881 37.9596H38.2357V16.9683H38.235C37.1878 16.9683 36.1963 16.6265 35.3713 16.051C34.8157 16.4417 34.1762 16.7304 33.4688 16.8714C33.1442 16.9361 32.8143 16.9683 32.4876 16.9683C32.2711 16.9683 32.0593 16.9442 31.8489 16.9168V32.5314C31.8489 33.4224 31.1262 34.145 30.2345 34.145H24.435V29.7226L26.4797 33.1351C26.7211 33.5386 27.1487 33.7621 27.5874 33.7621C27.8123 33.7621 28.0412 33.7029 28.249 33.5785C28.8595 33.2121 29.0582 32.4206 28.6916 31.8094L24.5814 24.9489C24.327 24.5232 23.8649 24.3129 23.4023 24.339C23.3713 24.3362 18.6401 24.3355 18.6112 24.3376C18.1423 24.3053 17.6734 24.5177 17.4149 24.9489L13.3046 31.8094C12.9381 32.4206 13.1368 33.2121 13.7474 33.5785C13.9551 33.7029 14.184 33.7621 14.4089 33.7621C14.8476 33.7621 15.2753 33.5386 15.5166 33.1351L17.5744 29.7007V34.145H11.7625C10.8707 34.145 10.1481 33.4225 10.1481 32.5314V16.9168C9.93782 16.9442 9.72594 16.9683 9.50938 16.9683C9.18484 16.9683 8.85475 16.9361 8.52484 16.8707C7.81796 16.7298 7.17988 16.4417 6.625 16.051C5.80001 16.6265 4.80923 16.9683 3.76195 16.9683H3.76126V37.9596H2.90879C1.79288 37.9596 0.888672 38.8638 0.888672 39.9798C0.888672 41.0957 1.79288 41.9999 2.90879 41.9999H39.0884C40.2044 41.9999 41.1085 41.0957 41.1085 39.9798C41.1083 38.8638 40.2041 37.9596 39.0881 37.9596Z" fill="#2FB95D" />
                                            <path d="M20.9976 17.0916C19.5144 17.0916 18.3105 18.4674 18.3105 20.1657C18.3105 21.8626 19.5144 23.2398 20.9976 23.2398C22.4821 23.2398 23.6853 21.8626 23.6853 20.1657C23.6852 18.4674 22.4821 17.0916 20.9976 17.0916Z" fill="#2FB95D" />
                                            <path d="M2.94018 14.6934C3.21463 14.7745 3.49099 14.8136 3.76397 14.8136C5.00016 14.8136 6.14303 14.0085 6.51488 12.7627L6.6661 12.2573C6.80155 13.4682 7.68851 14.5077 8.9468 14.758C9.13581 14.7958 9.3249 14.8136 9.51061 14.8136C10.8534 14.8136 12.0532 13.8677 12.3255 12.5014L12.3956 12.1501C12.4946 13.5176 13.5576 14.659 14.9684 14.7993C15.0655 14.8088 15.1617 14.813 15.2573 14.813C16.715 14.813 17.9642 13.7074 18.1121 12.225L18.1334 12.0119C18.1726 13.5651 19.4371 14.813 20.9992 14.813C22.5613 14.813 23.8258 13.5651 23.865 12.0119L23.8862 12.225C24.0341 13.7074 25.2834 14.813 26.7411 14.813C26.8367 14.813 26.9329 14.8089 27.0299 14.7993C28.4407 14.659 29.5044 13.5176 29.6027 12.1501L29.6728 12.5014C29.9452 13.8683 31.1449 14.8136 32.4876 14.8136C32.6734 14.8136 32.8625 14.7958 33.0515 14.758C34.3098 14.5077 35.1975 13.4682 35.3322 12.2573L35.4834 12.7627C35.8553 14.0086 36.9981 14.8136 38.2343 14.8136C38.5072 14.8136 38.7836 14.7744 39.0581 14.6934C40.5789 14.2387 41.4425 12.6381 40.9888 11.1187L38.3958 2.44016C37.9634 0.992164 36.6316 0 35.1204 0H6.8779C5.36664 0 4.03486 0.992164 3.60243 2.44016L1.0095 11.1187C0.555748 12.6381 1.4194 14.2387 2.94018 14.6934Z" fill="#2FB95D" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span className='sub_heading_home mx-4 leftToRightAnimation'>Explore Mandi Rates</span>
                            <p className='paraheading'>Explore <b>Current Mandi Rates</b> to make informed decisions for your agricultural endeavors.</p>
                            <div className="container d-flex justify-content-center align-items-center " >
                                <img
                                    src={mobile2}
                                    className='img effortlessmobil img-fluid'
                                    alt="Main"
                                    style={{ maxWidth: '178px', maxHeight: '360px' }}
                                />
                            </div>
                        </div>
                        <div className='justify-content-center text-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                                    <g filter="url(#filter0_d_1909_3081)">
                                        <circle cx="47" cy="48" r="40" fill="white" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="1" />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                        </filter>
                                    </defs>
                                    <g transform="translate(28, 28)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                            <path d="M9.625 32.375V27.125C9.62159 25.3258 10.0468 23.5518 10.8654 21.9497C11.6841 20.3476 12.8726 18.9635 14.3325 17.9121C13.3479 16.7588 12.682 15.3682 12.4008 13.8781C12.1196 12.3881 12.2329 10.8504 12.7295 9.41759C11.4555 8.73795 9.97052 8.5689 8.57636 8.9448C7.1822 9.3207 5.98348 10.2133 5.22381 11.4413C4.46415 12.6693 4.20055 14.1404 4.48659 15.5557C4.77263 16.9711 5.58684 18.2244 6.76375 19.061C5.27608 19.7057 4.0091 20.771 3.11842 22.1258C2.22773 23.4806 1.75212 25.0661 1.75 26.6875V30.625C1.75139 31.7849 2.21277 32.8969 3.03294 33.717C3.85311 34.5372 4.9651 34.9986 6.125 35H10.2244C9.83101 34.1807 9.6262 33.2838 9.625 32.375Z" fill="#2FB95D" />
                                            <path d="M35.2367 19.061C36.4136 18.2244 37.2278 16.9711 37.5139 15.5557C37.7999 14.1404 37.5363 12.6693 36.7767 11.4413C36.017 10.2133 34.8183 9.3207 33.4241 8.9448C32.0299 8.5689 30.545 8.73795 29.271 9.41759C29.7675 10.8504 29.8808 12.3881 29.5996 13.8781C29.3184 15.3682 28.6525 16.7588 27.668 17.9121C29.1279 18.9635 30.3164 20.3476 31.135 21.9497C31.9537 23.5518 32.3789 25.3258 32.3755 27.125V32.375C32.3743 33.2838 32.1695 34.1807 31.7761 35H35.8755C37.0354 34.9986 38.1474 34.5372 38.9675 33.717C39.7877 32.8969 40.2491 31.7849 40.2505 30.625V26.6875C40.2483 25.0661 39.7727 23.4806 38.882 22.1258C37.9914 20.771 36.7244 19.7057 35.2367 19.061Z" fill="#2FB95D" />
                                            <path d="M24.6374 18.2192C25.9423 17.4256 26.9518 16.227 27.512 14.8062C28.0723 13.3854 28.1525 11.8204 27.7404 10.3497C27.3283 8.8791 26.4465 7.5836 25.2296 6.66078C24.0126 5.73796 22.5273 5.23846 21 5.23846C19.4727 5.23846 17.9874 5.73796 16.7704 6.66078C15.5535 7.5836 14.6717 8.8791 14.2596 10.3497C13.8475 11.8204 13.9277 13.3854 14.488 14.8062C15.0482 16.227 16.0577 17.4256 17.3626 18.2192C15.5939 18.9426 14.0803 20.1755 13.0141 21.7613C11.9479 23.3471 11.3774 25.2141 11.375 27.125V32.375C11.3764 33.5349 11.8378 34.6469 12.6579 35.467C13.4781 36.2872 14.5901 36.7486 15.75 36.75H26.25C27.4099 36.7486 28.5219 36.2872 29.3421 35.467C30.1622 34.6469 30.6236 33.5349 30.625 32.375V27.125C30.6226 25.2141 30.0521 23.3471 28.9859 21.7613C27.9197 20.1755 26.4061 18.9426 24.6374 18.2192Z" fill="#2FB95D" />
                                        </svg>
                                    </g>
                                </svg>
                            </div>
                            <span className='sub_heading_home mx-4 leftToRightAnimation'>Find Farm Community</span>
                            <p className='paraheading'>Connect with like-minded farmers and join a thriving <b>Farmers Community</b> where knowledge is shared.</p>
                            <div class="container d-flex justify-content-center align-items-center " >
                                <img
                                    src={mobile3}
                                    className='img effortlessmobil img-fluid'
                                    alt="Main"
                                    style={{ maxWidth: '178px', maxHeight: '360px' }}
                                />
                            </div>
                        </div>
                        <div className='justify-content-center text-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                                    <g filter="url(#filter0_d_1909_3081)">
                                        <circle cx="47" cy="48" r="40" fill="white" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1909_3081" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="1" />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1909_3081" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1909_3081" result="shape" />
                                        </filter>
                                    </defs>
                                    <g transform="translate(28, 28)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                            <path d="M30.2798 22.9097V39.375C30.2798 39.549 30.2107 39.7159 30.0876 39.839C29.9646 39.9621 29.7977 40.0312 29.6236 40.0312H7.12733C6.95329 40.0312 6.78641 39.9621 6.6633 39.839C6.54024 39.7159 6.47111 39.549 6.47111 39.375V22.9097C6.46803 22.8901 6.46803 22.8702 6.47111 22.8506C6.46796 22.8113 6.46796 22.7718 6.47111 22.7325C6.48293 22.6887 6.50059 22.6468 6.52361 22.6078C6.5394 22.5749 6.55924 22.544 6.58264 22.5159C6.61014 22.4763 6.64335 22.4409 6.68108 22.4109C6.69257 22.3962 6.70576 22.383 6.72046 22.3716L17.9686 14.4966C18.0784 14.4203 18.209 14.3795 18.3426 14.3795C18.4764 14.3795 18.6069 14.4203 18.7167 14.4966L29.9648 22.3716C29.9796 22.383 29.9928 22.3962 30.0042 22.4109C30.042 22.4409 30.0751 22.4763 30.1026 22.5159C30.1261 22.544 30.146 22.5749 30.1617 22.6078C30.1847 22.6468 30.2024 22.6887 30.2142 22.7325C30.2168 22.7696 30.2168 22.8069 30.2142 22.8441C30.2395 22.8622 30.2617 22.8844 30.2798 22.9097Z" fill="#2FB95D" />
                                            <path d="M23.2122 27.9695V40.0314H13.5391V27.9695C13.5391 27.7954 13.6082 27.6285 13.7313 27.5054C13.8543 27.3824 14.0213 27.3132 14.1953 27.3132H22.5559C22.73 27.3132 22.8969 27.3824 23.02 27.5054C23.143 27.6285 23.2122 27.7954 23.2122 27.9695Z" fill="white" />
                                            <path d="M28.0742 1.96875C27.9002 1.96875 27.7333 2.03789 27.6102 2.16096C27.4871 2.28403 27.418 2.45095 27.418 2.625C27.418 2.79905 27.4871 2.96597 27.6102 3.08904C27.7333 3.21211 27.9002 3.28125 28.0742 3.28125C30.9027 3.2934 33.611 4.42597 35.6061 6.43089C37.6011 8.43583 38.7204 11.1497 38.7186 13.9781C38.7186 14.1522 38.7878 14.3191 38.9108 14.4422C39.0339 14.5652 39.2008 14.6344 39.3748 14.6344C39.5489 14.6344 39.7158 14.5652 39.8389 14.4422C39.962 14.3191 40.0311 14.1522 40.0311 13.9781C40.0346 10.8011 38.7777 7.75235 36.536 5.50091C34.2945 3.24947 31.2513 1.97918 28.0742 1.96875Z" fill="#2FB95D" />
                                            <path d="M28.0742 5.14505C27.9002 5.14505 27.7333 5.21419 27.6102 5.33726C27.4871 5.46033 27.418 5.62725 27.418 5.8013C27.418 5.97534 27.4871 6.14227 27.6102 6.26533C27.7333 6.38841 27.9002 6.45755 28.0742 6.45755C30.0608 6.46796 31.9622 7.26533 33.362 8.67496C34.7619 10.0846 35.5459 11.9916 35.5423 13.9782C35.5423 14.1522 35.6115 14.3192 35.7346 14.4422C35.8577 14.5653 36.0246 14.6344 36.1986 14.6344C36.3726 14.6344 36.5396 14.5653 36.6626 14.4422C36.7857 14.3192 36.8548 14.1522 36.8548 13.9782C36.8584 11.6435 35.936 9.40268 34.2901 7.74689C32.6441 6.09111 30.4089 5.15547 28.0742 5.14505Z" fill="#2FB95D" />
                                            <path d="M28.0801 8.3147C27.906 8.3147 27.7392 8.38387 27.616 8.50691C27.493 8.63003 27.4238 8.79691 27.4238 8.97095C27.4238 9.14498 27.493 9.31193 27.616 9.43498C27.7392 9.55809 27.906 9.6272 28.0801 9.6272C29.226 9.63763 30.3212 10.101 31.1266 10.9162C31.932 11.7315 32.3821 12.8322 32.3785 13.9781C32.3785 14.1522 32.4477 14.3191 32.5707 14.4422C32.6938 14.5653 32.8607 14.6344 33.0348 14.6344C33.2089 14.6344 33.3758 14.5653 33.4988 14.4422C33.6219 14.3191 33.691 14.1522 33.691 13.9781C33.6946 12.4841 33.1062 11.0495 32.0547 9.98813C31.0031 8.92678 29.5741 8.32513 28.0801 8.3147Z" fill="#2FB95D" />
                                            <path d="M28.0534 11.4976C27.5661 11.4963 27.0894 11.6397 26.6838 11.9096C26.2781 12.1795 25.9616 12.5638 25.7745 13.0137C25.5875 13.4637 25.5381 13.959 25.6329 14.437C25.7277 14.915 25.9622 15.3541 26.3067 15.6987C26.6513 16.0432 27.0904 16.2778 27.5684 16.3725C28.0464 16.4672 28.5417 16.4179 28.9917 16.2309C29.4416 16.0438 29.8259 15.7274 30.0958 15.3217C30.3657 14.916 30.5091 14.4393 30.5078 13.9519C30.5061 13.3015 30.2469 12.6783 29.787 12.2184C29.3271 11.7585 28.7038 11.4993 28.0534 11.4976Z" fill="#2FB95D" />
                                            <path d="M9.96877 13.5976C9.58112 13.2187 9.06051 13.0067 8.51845 13.0069C8.2465 13.0077 7.97737 13.0626 7.72675 13.1682C7.4762 13.2739 7.249 13.4283 7.05856 13.6224C6.86805 13.8165 6.71803 14.0466 6.61716 14.2991C6.51632 14.5517 6.46662 14.8219 6.47097 15.0938V19.8844C6.47097 19.8844 6.47097 19.9304 6.47097 19.9501C6.47847 20.0379 6.50537 20.1231 6.54972 20.1994C6.54972 20.1994 6.54972 20.2454 6.54972 20.2585L6.58251 20.2913C6.62931 20.3459 6.68469 20.3924 6.74658 20.4291L6.8122 20.4751C6.89824 20.5167 6.99228 20.5391 7.08783 20.5407C7.16651 20.5392 7.24434 20.5237 7.31751 20.4947L7.38314 20.4619C7.41123 20.4528 7.43774 20.4395 7.46189 20.4226L10.251 18.4538C10.2657 18.4424 10.2789 18.4291 10.2903 18.4144C10.3243 18.3848 10.355 18.3518 10.3822 18.316L10.4544 18.2176C10.4734 18.1803 10.4866 18.1405 10.4938 18.0994C10.5005 18.0559 10.5005 18.0116 10.4938 17.9682C10.4967 17.9508 10.4967 17.933 10.4938 17.9157V15.0938C10.5232 14.5453 10.3344 14.0074 9.96877 13.5976Z" fill="#2FB95D" />
                                            <path d="M33.9672 22.1025L18.7488 11.4253C18.6389 11.3491 18.5085 11.3083 18.3747 11.3083C18.241 11.3083 18.1105 11.3491 18.0007 11.4253L2.78224 22.1025C2.57713 22.246 2.40229 22.4285 2.26771 22.6396C2.13312 22.8506 2.04143 23.0861 1.99786 23.3326C1.90986 23.8304 2.02323 24.3428 2.31302 24.757C2.60281 25.1713 3.04528 25.4534 3.54311 25.5414C4.04093 25.6294 4.55331 25.516 4.96755 25.2262L18.3747 15.8156L31.7819 25.2262C32.1961 25.516 32.7085 25.6294 33.2064 25.5414C33.7042 25.4534 34.1466 25.1713 34.4364 24.757C34.7262 24.3428 34.8396 23.8304 34.7516 23.3326C34.6636 22.8348 34.3814 22.3923 33.9672 22.1025Z" fill="#2FB95D" />
                                        </svg>
                                    </g>
                                </svg>
                            </div>
                            <span className='sub_heading_home mx-4 leftToRightAnimation'>Seamless IoT Farm experience</span>
                            <p className='paraheading'>Experience the future of agriculture with our seamless IoT farming solutions. </p>
                            <div class="container d-flex justify-content-center align-items-center " >
                                <img
                                    src={mobile4}
                                    className='img effortlessmobil img-fluid'
                                    alt="Main"
                                    style={{ maxWidth: '178px', maxHeight: '360px' }}
                                />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="row mt-5 mb-2 justify-content-center">
                <div class="col-md-8 text-center">
                    <h3> <span className='line_heading'></span>Feature Fresh</h3>
                    <h2 className='heading_home'>
                        Precision Farming <span className='heading_home_color'>At Your Fingertips</span>
                    </h2>
                    <div className='mt-5'>
                        <img src={optimizetask} alt="best_farming_app_fasalam" className="img-fluid" />
                    </div>
                    <div className="text-center">
                        <Link to="/features/" style={{ textDecoration: "none" }}>
                            <div className='viewallproduct'>
                                View App Features &rarr;
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Iot Product section----------- */}
            {/* <div className="row mt-5 mb-2 justify-content-center">
                <div class="col-md-10 text-center">
                    <h4> <span className='line_heading'></span>Our IoT Powered Products</h4>
                    <p className='heading_home'>
                        Do Check Our <span className='heading_home_color'>IoT Powered products</span>
                    </p>
                </div>
                <div className="col-md-10 text-center ">
                    <div className="testimonial-slider-container ">
                        <Slider ref={sliderRef3} {...sliderSettings3}>
                            {IOTproduct.map((data, index) => {
                                return (
                                    <div className="testimonial-slide">
                                        <div className="card custom-card-style_blog" style={{ width: "20rem" }}>
                                            <div className='card_blog_first' style={{ textAlign: 'center' }}>
                                                <img src={data.img} alt={`Blog Image`} className="img-fluid" style={{
                                                    maxWidth: '100%',
                                                    height: '150px',
                                                    display: 'block',
                                                    margin: '0 auto'
                                                }} />
                                                <div className="card-body" style={{ width: '100%', height: '100px' }}>
                                                    <h5 className="card-title card_home_heading text-center">{data.name}</h5>
                                                </div>
                                            </div>

                                            <span
                                                className={`readmore_blog text-center mt-2 ${hoveredCard === index ? 'show-arrow' : ''}`}
                                                onMouseEnter={() => handleHoverExplore(index)}
                                                onMouseLeave={() => handleHoverExplore(null)}
                                            >
                                                <Link to={data.url} className="readmore_blog text-center mt-2"
                                                    style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                    {hoveredCard === index ? (
                                                        <>
                                                            Read more <FontAwesomeIcon icon={faArrowRight} />
                                                        </>
                                                    ) : (
                                                        'Read more'
                                                    )}
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                <div className="col-md-10 mt-3 ">
                    <div className='d-flex justify-content-center mb-3'>
                        <span className='mx-md-5'>
                            <span className='mx-md-4'>
                                <button className="circular-button mx-2" onClick={goToPreviousSlide3}>
                                    <i className="fa fa-chevron-left" />
                                </button>
                                <button className="circular-button mx-2" onClick={goToNextSlide3}>
                                    <i className="fa fa-chevron-right" />
                                </button>
                            </span>
                        </span>
                    </div>
                </div>
            </div> */}
            {/* <Iotproduct /> */}
            {/* <div className="row mt-5 mb-2 justify-content-center ">
                <div class=" text-center">
                    <h4> <span className='line_heading'></span>View Our Products</h4>
                    <p className='heading_home'>
                        Do Check<span className='heading_home_color'> Our IoT Products</span>
                    </p>
                    <div className='row mt-5 contact-f' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <div className='col-md-8 text-center ourproductbackground' style={{ position: 'absolute', bottom: -200, left: '50%', transform: 'translateX(-50%)', zIndex: 0}}>
                            <div  style={{ zIndex:1}} >
                                <img src={ourProduct} alt="best_farming_app_fasalam" className="img-fluid" useMap="#productmap" style={{position:"relative",zIndex:1}}/>
                            </div>
                            <map name="productmap">
                                <area shape="circle" coords="40,180,200,200" alt="Clickable Area 1" href="products/gsm-motor-controller/" />
                                <area shape="circle" coords="300,100,50" alt="Clickable Area 2" href="/products/phase-preventer-dol" />
                            </map>
                            <div className="text-center mb-3">
                                <Link to="/products/" style={{ textDecoration: "none" }}>
                                    <div className='viewallproduct'>
                                        View All Products &rarr;
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='home_empty' style={{ height: "200px" }}>
                    </div>
                </div>
            </div> */}

            <div className="row mt-5 mb-2 justify-content-center">
                <div className="col-12 text-center p-0">
                    <h3><span className='line_heading'></span>View Our Products</h3>
                    <h2 className='heading_home'>
                        Do Check<span className='heading_home_color'> Our IoT Products</span>
                    </h2>
                    <div className='row mt-5'>
                        <div className='col-12 text-center p-0'>
                            <img src={ourProductnew} alt="best_farming_app_fasalam" className="img-fluid" id="productImage" />
                            <div className="text-center mb-3">
                                <Link to="/shop/" style={{ textDecoration: "none" }}>
                                    <div className='viewallproduct'>
                                        View All Products &rarr;
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className='home_empty' style={{ height: "200px" }}>
        </div> */}
                </div>
            </div>



            {/* Faq section -------------------------*/}
            <div className="row justify-content-center text-center mx-auto mt-5 mb-5">
                <h3 className='sub_heading_home'> <span className='line_heading'></span>FAQs</h3>
                <h2 className='heading_home_color'>Questions? <span className='heading_home'>Look here.</span></h2>

                <div animationIn="flipInY" className="col-md-5">
                    {faqData.slice(0, 3).map((faq, index) => (
                        <div className='question_faq mb-3' key={index}>
                            <div className='faq_question_container'>
                                <p className='text-start faq_question mt-3 mb-3 py-3 px-3 mr-4' onClick={() => toggleAnswer1(index)} style={{ cursor: 'pointer' }}>
                                    {faq.question}
                                    {openIndex === index ? <span> &#x25B2; </span> : <span> &#x25BC; </span>}
                                </p>
                                {openIndex === index && <p className='text-start faq_answer px-3'>{faq.answer}</p>}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="col-md-5">
                    {faqData.slice(3).map((faq, index) => (
                        <div className='question_faq mb-3' key={index + 3}>
                            <div className='faq_question_container'>
                                <p className='text-start faq_question mt-3 py-3 mb-3 px-3' onClick={() => toggleAnswer1(index + 3)} style={{ cursor: 'pointer' }}>
                                    {faq.question}
                                    {openIndex === index + 3 ? <span> &#x25B2; </span> : <span> &#x25BC; </span>}
                                </p>
                                {openIndex === index + 3 && <p className='text-start faq_answer px-3'>{faq.answer}</p>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Review Section -------------------- */}
            <div className="row justify-content-center  mx-auto mt-4 ">
                <div className="col-md-10 ">

                    <h3 className='text-center'><span className='line_heading'></span>Testimonal</h3>
                    <h2 className="text-center mb-4 heading_home">Visit our <span className='heading_home_color'>User Reviews</span></h2>

                    <div className="carousel-container">
                        <div className="d-flex flex-column">
                            <div className="carousel-items">
                                {/* Render 5 items on the screen */}
                                {Array(5)
                                    .fill()
                                    .map((_, index) => {
                                        const imageItem =
                                            images[
                                            getCircularIndex(currentIndex + index - 2, images.length)
                                            ];
                                        return (
                                            <div
                                                key={index}
                                                className={`${index === 2 ? "center" : "opacity"} ${(index === 0 || index === 4) &&
                                                    "end d-none d-md-block d-lg-block"
                                                    }
                                                 ${(index === 1 || index === 3) &&
                                                    "d-none d-md-block d-lg-block"
                                                    }`}
                                                onClick={() =>
                                                    handleItemClick(
                                                        getCircularIndex(currentIndex + index - 2, images.length)
                                                    )
                                                }
                                            >
                                                <img src={imageItem.img} alt="item" className='caroselimge' />
                                            </div>
                                        );
                                    })}
                            </div>
                            <div>
                                <div className="description">
                                    <p className='text-center paraheading'>{images[currentIndex].name}</p>
                                    <p className='text-center'>
                                        {Array.from({ length: 5 }).map((_, starIndex) => {
                                            const isFilled = starIndex < Math.floor(images[currentIndex].rating);
                                            const isHalfFilled = starIndex === Math.floor(images[currentIndex].rating) && images[currentIndex].rating % 1 !== 0;

                                            return (
                                                <span
                                                    key={starIndex}
                                                    className={`fa fa-star${isFilled ? " checked" : ""}`}
                                                    style={{
                                                        fontSize: "30px",
                                                        backgroundImage: isHalfFilled ? "linear-gradient(to right, green 50%, black 50%)" : "none",
                                                        WebkitBackgroundClip: isHalfFilled ? "text" : "none",
                                                        color: isHalfFilled ? "transparent" : (isFilled ? "green" : "inherit"),
                                                    }}
                                                ></span>
                                            );
                                        })}
                                    </p>
                                    <p className='text-center paraheading'>{images[currentIndex].desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Video section --------------------- */}
            <div className="row justify-content-center text-center mx-auto  mt-5">

                <h3><span className='line_heading'></span>View Video Highlights</h3>
                <h2 className='heading_home'>Check out our <span className='heading_home_color'>video highlights</span></h2>

                <div className="col-10">
                    {Videodata.length > 0 && (
                        <div className="position-relative">
                            <YouTubePlayer videoId={Videodata[0].link} />
                        </div>
                    )}
                </div>
                <div className="col-10 mt-3 mb-5">
                    <div className="row d-flex justify-content-center text-center">

                        <div className="col-4 col-md-4 mb-3 position-relative">

                            {Videodata && Videodata.length > 0 && (
                                <YouTubePlayersmall videoId={Videodata[1].link} />
                            )}

                        </div>
                        <div className="col-4 col-md-4 mb-3 position-relative">

                            {Videodata && Videodata.length > 0 && (
                                <YouTubePlayersmall videoId={Videodata[2].link} />
                            )}

                        </div>
                        <div className="col-4 col-md-4 mb-3 position-relative">

                            {Videodata && Videodata.length > 0 && (
                                <YouTubePlayersmall videoId={Videodata[3].link} />
                            )}

                        </div>
                    </div>
                </div>
            </div>

            {/* News and blog section --------------- */}
            <div className="row  mb-5 client-feedback-mobile justify-content-end">
                <div className="col-md-11 ">
                    <h3 className='text-start sub_heading_home blog our'><span className='line_heading'></span>Blogs & News</h3>
                    <h2 className="text-start mb-5 heading_home our">Our Latest <span className='heading_home_color'>Blog & News</span> </h2>

                    <div className='d-flex justify-content-end d-none d-md-flex d-md-block mb-3'>
                        <span className='mx-md-5'>
                            <span className='mx-md-4'>
                                <button className="circular-button mx-2" onClick={goToPreviousSlide}>
                                    <i className="fa fa-chevron-left" />
                                </button>
                                <button className="circular-button mx-2" onClick={goToNextSlide}>
                                    <i className="fa fa-chevron-right" />
                                </button>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="col-md-11 text-center">
                    <div className="testimonial-slider-container ">
                        <Slider ref={sliderRef2} {...sliderSettings}>
                            {bloglists && bloglists.slice(0, 4).map((data, index) => (
                                <div key={index} className="testimonial-slide">
                                    <div className="card custom-card-style_blog" style={{ width: "20rem" }}>
                                        <div className='card_blog_first'>
                                            <img src={data.image} alt={`Blog Image ${index}`} className="img-fluid all-blog_img" />
                                            <div className="card-body">
                                                <h2 className="card-title card_home_heading text-start">
                                                    {data.title.length > 40 ? `${data.title.substring(0, 40)}...` : data.title}
                                                    {data.title.length > 40}
                                                </h2>
                                                {data.content && (
                                                    <p
                                                        className="card-text faq_answer text-start"
                                                        dangerouslySetInnerHTML={{
                                                            __html: truncateHtml(data.content, 40)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <span
                                            className={`readmore_blog text-center mt-2 ${hoveredCard === index ? 'show-arrow' : ''}`}
                                            onMouseEnter={() => handleHoverExplore(index)}
                                            onMouseLeave={() => handleHoverExplore(null)}
                                            onClick={() => {
                                                toComponentB(data);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {hoveredCard === index ? (
                                                <>
                                                    Read more <FontAwesomeIcon icon={faArrowRight} />
                                                </>
                                            ) : (
                                                'Read more'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>

                    {/* for mobile veiw  */}
                    <div className='d-flex justify-content-center  d-block d-md-none  mt-3'>
                        <span className='mx-md-5'>
                            <span className='mx-md-4'>
                                <button className="circular-button mx-2" onClick={goToPreviousSlide}>
                                    <i className="fa fa-chevron-left" />
                                </button>
                                <button className="circular-button mx-2" onClick={goToNextSlide}>
                                    <i className="fa fa-chevron-right" />
                                </button>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: '150px' }}>
            </div>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
            <Modal show={showModal} onHide={handleModalClose}
            //  scrollable
            >
                <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                    {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="col-md-10 col-sm-10 text-center mx-auto">
                        <Demoformhome onSubmit={handleSubmit} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModal1} onHide={handleModalClose1}
            //  scrollable
            >
                <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                    {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="text-center mb-5">
                    <div className="col-md-12 d-flex ">
                        <div className="card mx-2" style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                            <div className="card-body">
                                <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For iOS</h3>
                                <button className="btn buttonfunctionaity_farming btn-blocks mb-2" onClick={handleDownloadClick_ios}>Download app</button>
                                <div className='mt-md-4 mx-md-3 text-start'>
                                    <img src={iosqr} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                                </div>
                                <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                        <g transform="translate(30, 30)">
                                            <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1747 7.79248C28.7402 5.77168 29.9269 2.91548 29.4977 0C26.9391 0.177808 23.9485 1.81479 22.2035 3.94849C20.6128 5.88179 19.3054 8.75777 19.816 11.5491C22.6131 11.6366 25.4999 9.96004 27.1747 7.79248ZM41 35.2172C39.8806 37.7122 39.342 38.827 37.9 41.0369C35.8885 44.1217 33.0522 47.963 29.5341 47.9912C26.4117 48.0251 25.6065 45.945 21.3674 45.9704C17.1284 45.993 16.2447 48.0307 13.1166 47.9996C9.60139 47.9686 6.91377 44.5027 4.90226 41.4179C-0.725479 32.7985 -1.31743 22.6804 2.15291 17.2982C4.62171 13.4767 8.51568 11.2414 12.174 11.2414C15.8968 11.2414 18.2394 13.2961 21.3226 13.2961C24.3132 13.2961 26.1339 11.2358 30.4403 11.2358C33.7002 11.2358 37.1537 13.0223 39.6113 16.1043C31.554 20.5467 32.8586 32.1211 41 35.2172Z" fill="#FBFBFB" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="card mx-2 " style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                            <div className="card-body">
                                <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For Android</h3>
                                <button className="btn buttonfunctionaity_farming btn-blocks mb-2"
                                    onClick={handleDownloadClick_android} >Download app</button>
                                <div className='mt-md-4 mx-md-3 text-start '>
                                    <img src={qrforandroid} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                                </div>
                                <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                        <g transform="translate(30, 30)">
                                            <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                            <g clip-path="url(#clip0_1617_5839)">
                                                <path d="M6.66272 15.548C5.83593 15.548 5.13341 15.8362 4.55669 16.4135C3.97997 16.9905 3.69141 17.6827 3.69141 18.4903V30.8949C3.69141 31.7213 3.97987 32.4232 4.55669 33.0002C5.13341 33.5773 5.83552 33.8653 6.66241 33.8653C7.48951 33.8653 8.18657 33.5772 8.75379 33.0002C9.32142 32.4232 9.60503 31.7213 9.60503 30.8949V18.4903C9.60503 17.6824 9.31637 16.9902 8.73965 16.4135C8.16282 15.8365 7.47021 15.548 6.66272 15.548Z" fill="#FBFBFB" />
                                                <path d="M10.7598 35.3083C10.7598 36.1927 11.0675 36.9427 11.6828 37.5583C12.2983 38.1731 13.0481 38.4807 13.9329 38.4807H16.0677L16.0968 45.029C16.0968 45.8561 16.3852 46.5576 16.9621 47.1347C17.5388 47.7117 18.2313 48.0001 19.0389 48.0001C19.8662 48.0001 20.5679 47.7117 21.1449 47.1347C21.7219 46.5576 22.0102 45.8561 22.0102 45.029V38.4807H25.9908V45.029C25.9908 45.8561 26.2791 46.5576 26.8559 47.1347C27.4333 47.7117 28.1352 48.0001 28.9623 48.0001C29.7887 48.0001 30.4906 47.7117 31.0673 47.1347C31.644 46.5576 31.9321 45.8561 31.9321 45.029V38.4807H34.0959C34.9608 38.4807 35.7014 38.1737 36.3167 37.5583C36.9322 36.9427 37.24 36.1927 37.24 35.3083V16.0961H10.7598V35.3083Z" fill="#FBFBFB" />
                                                <path d="M30.5477 4.41346L32.5953 0.634356C32.7302 0.384389 32.6817 0.192115 32.4514 0.057634C32.201 -0.0578519 32.0088 -0.000159481 31.8747 0.230812L29.7973 4.03841C27.9704 3.23072 26.0382 2.82687 23.9994 2.82687C21.9611 2.82687 20.0282 3.23072 18.2012 4.03841L16.1242 0.230913C15.9901 -5.84507e-05 15.7975 -0.0577509 15.5475 0.057735C15.3166 0.192216 15.2689 0.38449 15.4034 0.634457L17.4516 4.41346C15.3743 5.47102 13.721 6.94708 12.4898 8.84133C11.2591 10.7357 10.6436 12.808 10.6436 15.0577H37.3271C37.3271 12.808 36.7116 10.7356 35.4808 8.84133C34.2495 6.94708 32.6061 5.47092 30.5477 4.41346ZM18.706 9.90849C18.4851 10.1298 18.2208 10.2403 17.913 10.2403C17.6052 10.2403 17.3458 10.1299 17.134 9.90849C16.9227 9.68752 16.8167 9.42301 16.8167 9.11535C16.8167 8.80759 16.9227 8.54307 17.134 8.3218C17.3455 8.10052 17.6053 7.99019 17.913 7.99019C18.2204 7.99019 18.4848 8.10042 18.706 8.3218C18.9273 8.54307 19.0379 8.80759 19.0379 9.11535C19.0379 9.42311 18.9273 9.68722 18.706 9.90849ZM30.8654 9.90849C30.6534 10.1298 30.3938 10.2403 30.0861 10.2403C29.7783 10.2403 29.5145 10.1299 29.2932 9.90849C29.0719 9.68752 28.9617 9.42301 28.9617 9.11535C28.9617 8.80759 29.0719 8.54307 29.2932 8.3218C29.5144 8.10052 29.7783 7.99019 30.0861 7.99019C30.3938 7.99019 30.6534 8.10042 30.8654 8.3218C31.0764 8.54307 31.1826 8.80759 31.1826 9.11535C31.1826 9.42311 31.0767 9.68722 30.8654 9.90849Z" fill="#FBFBFB" />
                                                <path d="M43.4424 16.3989C42.8656 15.8317 42.1637 15.548 41.3366 15.548C40.5293 15.548 39.8369 15.8317 39.2602 16.3989C38.6832 16.9663 38.3945 17.6636 38.3945 18.4903V30.8949C38.3945 31.7213 38.6832 32.4232 39.2602 33.0002C39.8369 33.5773 40.5293 33.8653 41.3366 33.8653C42.1637 33.8653 42.8656 33.5772 43.4424 33.0002C44.0198 32.4232 44.3081 31.7213 44.3081 30.8949V18.4903C44.3081 17.6635 44.0198 16.9662 43.4424 16.3989Z" fill="#FBFBFB" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Home
