import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footerlogo from '../Images/Footer/Rashail Agro LOGO Footer Logo.png'
import Footerplaystore from '../Images/Footer/fasalam_farming_app_download_appstore.png'
import googleplay from '../Images/Footer/fasalam_farming_app_download_google_play.png'
import { Card, Modal, Button } from 'react-bootstrap';
import modelimg from '../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp'
import Demoform from './Demoform';
import twitterlogo from '../Images/Footer/Twitter-X-White-Logo-PNG.png'
import { FaXTwitter } from "react-icons/fa6";
import Demoformhome from './Demoformhome';


const Footer = () => {

    const [email, setEmail] = useState('');
    // console.log(email)
    const handleSubmit = async () => {
        try {
            const response = await fetch('https://sheetdb.io/api/v1/z739dd47c6f71', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (response.ok) {
            } else {
                const responseBody = await response.json();
                console.error('Error submitting form data:', responseBody);
            }
        } catch (error) {
            console.error('An unexpected error occurred', error);
        }
    };




    const handleIconClick = () => {
        handleSubmit();
    };

    const DivStyle = {
        backgroundColor: '#043A3A',
    };

    const elevateStyle = {

    };

    const [showModal, setShowModal] = useState(false);
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };




    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };

    const handleDownloadClick = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };


    const [productName, setproductName] = useState('Home enquiry');
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmitform = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const handleDownloadClick_android = () => {
        window.open('https://play.google.com/store/apps/details?id=com.rashailagro.fasalam', '_blank');
    };

    return (
        <>
            <div className='footer_firstdiv py-2'>
                <h2 className='download_footer mt-5'>Request a Free Demo</h2>
                <p className='explore_footer text-center'>Get in touch with our sales team, who can share more about the <br />product and answer any questions you have.</p>
                <div className="d-md-flex" style={{ height: "160px" }}>
                    <button
                        className="btn buttonfunctionaity_footer mb-md-2 mb-2"
                        // onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}
                        onClick={handleModalOpen}
                    >
                        Get Started
                    </button>
                </div>

            </div>
            <div className="container-fluid justify-content-center">
                <div className="row justify-content-center mt-5 pt-5" style={DivStyle}>
                    <div className="col-md-10 col-sm-10 mt-5 ">
                        <div className="row ">
                            {/* for mobile view -------------------- */}
                            {/* <div className="col-md-3 col-sm-3 px-2 mt-md-5 mt-2 mb-2 d-block d-md-none">
                                <div className="mt-5 footerhead" style={{ color: 'white', textDecoration: 'none', textAlign: 'center', fontSize: "16px" }}>
                                    Get the latest information
                                    <div className="mt-2 " style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                        <input type='text' placeholder='Email Address' className='footer_input ' />
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 27 16" fill="none" style={{ cursor: 'pointer', backgroundColor: '#2FB95D', borderRadius: "0px 10px 10px 0px", paddingLeft: '5px', paddingRight: '5px' }}
                                                className="svg_input">
                                                <path d="M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9H26V7H0V9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-3  col-sm-3 mb-md-4  px-2 mt-5 ">
                                <div className='mt-2'>
                                    <img src={Footerlogo} alt="rashail_agro_logo" className='rashailagro_footerlogo' width={250} />
                                </div>
                                <ul className="list-unstyled mb-0">

                                    <li className="py-2 footer-address" style={{ color: 'white', textAlign: 'start' }}>
                                        S1,Malwa Tower, Near ICICI Bank, Ashok Nagar, Indore,
                                        Madhya Pradesh - 452001
                                    </li>
                                    <li className="py-2 footer-address" style={{ color: 'white', textAlign: 'start' }} >
                                        Contact us: <a href="tel:+918349503619" alt="rashail-agro-mobilenumber" style={{ color: 'white', textDecoration: "none" }}>+91 83495 03619</a>
                                    </li>
                                </ul>
                            </div>
                            {/* Pages Column */}
                            <div className="col-md-3  col-sm-3 mb-md-4  px-2 px-sm-5 mt-2 mt-md-5 d-none d-md-block">
                                <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: 'white', textDecoration: 'none', textAlign: 'start' }}>Pages</p>
                                <ul className="list-unstyled mb-0">
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} /> */}
                                            Home
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/about-us/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} /> */}
                                            About Us
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/contact-us/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/blog/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            {/* Quick Links Column */}
                            <div className="col-md-3 col-sm-3 mb-md-4  px-1 mt-md-5 d-none d-md-block">
                                <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: 'white', textDecoration: 'none' }}>Quick Links</p>
                                <ul className="list-unstyled mb-0">
                                    {/* <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }}>
                                            Help & Support
                                        </Link>
                                    </li> */}
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/shop/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Products
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/features/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Fasalam
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/terms-condition/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                    <li className="py-1" style={{ textAlign: 'start' }}>
                                        <Link to="/privacy-policy/" className="text-gray hover-white footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                            {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                            </div>


                            {/* for mobile view -------------------- */}
                            <div className='d-flex d-block d-md-none'>
                                <div className="col-6  col-sm-6 mb-md-4  px-2 px-sm-5 mt-2 mt-md-5">
                                    <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: 'white', textDecoration: 'none', textAlign: 'start' }}>Pages</p>
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} /> */}
                                                Home
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/about-us/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} /> */}
                                                About Us
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/contact-us/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/blog/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Blog
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* Quick Links Column */}
                                <div className="col-6 col-sm-6 mb-md-4  px-1 mt-md-5">
                                    <p className="fs-16 mb-3 mt-4 letter-spacing-05 footerhead" style={{ color: 'white', textDecoration: 'none' }}>Quick Links</p>
                                    <ul className="list-unstyled mb-0">
                                        {/* <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }}>
                                               
                                                Help & Support
                                            </Link>
                                        </li> */}
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/shop/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Products
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/features/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Fasalam
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/terms-condition/" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li className="py-1" style={{ textAlign: 'start' }}>
                                            <Link to="/privacy-policy/" className="text-gray hover-white footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                {/* <FontAwesomeIcon icon={faChevronRight} style={{ color: '#7738F8' }} />  */}
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                            </div>


                            {/* Newsletter Column */}
                            <div className="col-md-3 col-sm-3 px-2 mt-md-5 mt-2 mb-2 text-center">
                                <div className="mt-3 footerhead" style={{ color: "white" }}>Download Fasalam  App</div>
                                <div className='justify-content-center mobile_footerappstore d-flex mt-2'>
                                    <span onClick={handleDownloadClick} style={{ cursor: "pointer" }}>
                                        <img src={Footerplaystore} alt="rashail-agro-footerplaystore" className='footer_playstore mb-2 text-center' width={100} />
                                    </span>
                                    <span
                                        //  onClick={handleModalOpen} 
                                        onClick={handleDownloadClick_android}
                                        style={{ cursor: "pointer" }}>
                                        <img src={googleplay} alt="rashail-agro-googleplay" className='footer_playstore mx-2  mb-2' width={100} />
                                    </span>
                                </div>
                                <div className="mt-2 footerhead justify-content-center" style={{ color: 'white', textDecoration: 'none' }}>
                                    Get Free Product Updates!
                                    <div className="mt-2 justify-content-center" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                        <input type='text'
                                            placeholder='Email Address'
                                            className='footer_input'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 27 16" fill="none" style={{ cursor: 'pointer', backgroundColor: '#2FB95D', borderRadius: "0px 10px 10px 0px", paddingLeft: '5px', paddingRight: '5px' }}
                                                className="svg_input"
                                                onClick={handleIconClick}>
                                                <path d="M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9H26V7H0V9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>

                                    <div className="mt-3 followfooter">
                                        Follow Our Social Network
                                        <div className="mt-2">
                                            <a href=" https://www.facebook.com/rashailagro/" target="_blank" alt="rashail-agro-facebook" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-facebook facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href="https://www.instagram.com/rashailagro/" target="_blank" alt="rashail-agro-instagram" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-instagram facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href="https://twitter.com/RashailAgro" target="_blank" alt="rashail-agro-twitter" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span className="" >
                                                    {/* <img src={twitterlogo} className='twitter_logo' alt="Twitter" style={{ width: '20px', height: '20px', marginRight: '20px',marginBottom:"8px", cursor: 'pointer' }} /> */}
                                                    <FaXTwitter className='facebook-mobile'  style={{ color: '#fff', fontSize: '22px', marginRight: '20px', cursor: "pointer" }}/>

                                                </span>
                                            </a>
                                            <a href="https://www.youtube.com/channel/UCiyVvtJtp-MPyYWgDBHXKRw" target="_blank" alt="rashail-agro-youtube" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-youtube-play facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href=" https://www.linkedin.com/showcase/rashail-agro/" target="_blank" alt="rashail-agro-linkedin" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-linkedin facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Copyright Section */}
                        <div className="row  justify-content-center ">
                            <div className="col-md-12 col-sm-12 copyright-mobile-footer text-end" style={{ color: '#FBFBFB', fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', borderTop: '1px solid #CCCCCC' }}>
                                <div className="row justify-content-between mt-2 mb-2 mb-md-2">
                                    <div className="col-md-12 col-sm-5 copyright-mobile-footer text-center " >
                                        <span className='footer-copyright'>All Copyright Reserved by Rashail Agro 2024.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose}
            //  scrollable
            >
                <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                    {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="col-md-10 col-sm-10 text-center mx-auto">
                        <Demoformhome  onSubmit={handleSubmitform} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Footer;
