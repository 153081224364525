import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { videourl } from "../Constants/urls";


const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
};

export const videolist = createAsyncThunk(
    'video/getvideo',
    async (page, { rejectWithValue }) => {
        try {
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(
                videourl,

                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const GetvideoSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(videolist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(videolist.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            })
            .addCase(videolist.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload;
            });
    },
});

export default GetvideoSlice.reducer;
