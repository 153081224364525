import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import { enquiryurl } from '../Constants/urls';

const Demoform = ({ onSubmit, productName }) => {
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        // email: '',
        product_name: productName,
        is_demo: 'true',
        // description: '',
        address: ''
    });

    const notify = (data) => toast(data);
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {

            const token = 'e606743946ca17935cdaf8074a5a6bfce1674f2b';
            const response = await fetch(enquiryurl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                notify('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
            } else {
                notify('Error submitting form');
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className='mt-3'>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <h3 className="card-title text-start mt-3 mb-3 productdetailsheading"><center>Request a Demo</center></h3>
                        </div>
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Full Name"
                                name="name"
                                type="text"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                    // style: { fontSize: '16px' },
                                }}
                                value={formData.name}
                                onChange={handleChange}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red', },
                                }}
                                fullWidth
                            />
                            {errors.name && <div className="error-message text-end">{errors.name}</div>}
                        </div>
                    </Box>
                </div>
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Phone"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                type="number"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                            {errors.phone_number && <div className="error-message text-end">{errors.phone_number}</div>}
                        </div>
                    </Box>
                </div>
                {/* <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                        </div>
                    </Box>
                    {errors.email && <div className="error-message text-end">{errors.email}</div>}
                </div> */}
                {/* <div class="mb-2">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Product Name.."
                                name="product_name"
                                value={formData.product_name}
                                onChange={handleChange}
                                type="text"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                        </div>
                    </Box>
                </div> */}
                {/* <div class="mb-2">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextareaAutosize
                                id="outlined-number"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                aria-label="Description"
                                placeholder="Description.."
                                className='text-area_contact'
                                minRows={3}
                                color="success"
                                focused
                                style={{
                                    borderRadius: '20px', borderColor: 'success', width: '100%',
                                    maxWidth: '620px', padding: '10px'
                                }}
                            />
                        </div>
                    </Box>
                </div> */}
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextareaAutosize
                                id="outlined-number"
                                aria-label="Address"
                                placeholder="Address.."
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                className='text-area_contact'
                                minRows={3}
                                color="success"
                                focused
                                style={{
                                    borderRadius: '20px', borderColor: 'success', width: '100%',
                                    maxWidth: '620px', padding: '10px'
                                }}
                            />
                        </div>
                    </Box>
                    {errors.address && <div className="error-message text-end">{errors.address}</div>}
                </div>
                <div className="mb-5">
                    <button
                        type="submit"
                        className="btn btn-primary submitreview_contactus"
                        style={{
                            width: '100%',
                            maxWidth: '620px'
                        }}
                        disabled={loading} >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Demoform
