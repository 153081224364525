import React, { useEffect, useRef, useState } from 'react'
import aboutLandingimg from '../Images/Aboutus/Group 1000007872.webp'
import drive1 from '../Images/Aboutus/farmer-4365877 1.webp'
import drive2 from '../Images/Aboutus/agriculture-6155388-1.webp'
import drive3 from '../Images/Aboutus/drive3.webp'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ourmission from '../Images/Aboutus/ourmission.webp'
import ourvision from '../Images/Aboutus/ourvision.webp'
import ourgoal from '../Images/Aboutus/ourgoal.webp'
import aboutdrive from '../Images/Aboutus/aboutdrive.png'
import { Helmet } from 'react-helmet'
import { initialImages } from '../Constants/Homedata';
import { Title, Meta, Link, HeadProvider } from "react-head";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import "odometer/themes/odometer-theme-default.css";


const Aboutus = () => {

    // Carousel
    const [images, setImages] = useState(initialImages);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [odometerValue, setOdometerValue] = useState(0);
    const [odometerValue1, setOdometerValue1] = useState();
    const [odometerValue2, setOdometerValue2] = useState();


    useEffect(() => {
        setTimeout(() => {
            setOdometerValue(2);
            setOdometerValue1(50);
            setOdometerValue2(24);
        }, 2000);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            moveItems();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const moveItems = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(nextIndex);
    };

    const handleItemClick = (index) => {
        setCurrentIndex(index);
    };

    const getCircularIndex = (index, length) => {
        return (index + length) % length;
    };

    return (
        <div className='container-fluid'>
            {/* <Helmet>
                <title>Rashail Agro: Driving Innovation in Agriculture
                </title>
                <meta name="description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <base target="_blank" href="https://rashailagro.in/about-us/" />
                <link rel="canonical" href="https://rashailagro.in/about-us/" />
                <meta name="keywords" content="iot farming, agricultural sensors, precise farming, iot smart farming, ai in agriculture, smart solution for agriculture, farmers app, krishi app, Rashail Agro, Rashail Automations, sustainable farming, agriculture technology, farming, farming practices, iot in farming" />
                <meta property="og:title" content="Rashail Agro: Driving Innovation in Agriculture" />
                <meta property="og:description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <meta property="og:url" content="https://rashailagro.in/about-us/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro: Driving Innovation in Agriculture" />
                <meta name="twitter:description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <meta property="twitter:url" content="https://rashailagro.in/about-us/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>Rashail Agro: Driving Innovation in Agriculture</Title>
                <Meta name="description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <base target="_blank" href="https://rashailagro.in/about-us/" />
                <Link rel="canonical" content="https://rashailagro.in/about-us/" />
                <Meta name="keywords" content="iot farming, agricultural sensors, precise farming, iot smart farming, ai in agriclture, smart solution for agriculture, farmers app, krishi app, Rashail Agro, Rashail Automations, sustainable farming, agriculture technology, farming, farming practices, iot in farming." />
                <Meta property="og:title" content="Rashail Agro: Driving Innovation in Agriculture" />
                <Meta property="og:description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <Meta property="og:url" content="https://rashailagro.in/about-us/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Rashail Agro: Driving Innovation in Agriculture" />
                <Meta name="twitter:description" content="Rashail Agro leverages technology to make farming more efficient and sustainable. Explore our IoT-powered mobile app and farming solutions." />
                <Meta property="twitter:url" content="https://rashailagro.in/about-us/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row Termconditionbackground d-flex align-items-center">
                <div className="col-md-11 col-sm-12 text-center mx-auto">
                    <h1 className='mt-5 '><span className='policy_heading'> About</span><span className='privacy_heading '> us </span></h1>
                    <h2 className='text-center border_new'> Unveiling the Story Behind Our Passion-Driven Endeavor.</h2>
                </div>
            </div>
            <div className="row justify-content-center mx-auto">
                <div className="col-md-5 order-lg-1">
                    <p className="mt-3 aboutusparaheading">
                        Nurturing Innovation: Rashail Agro Unveils a New Era in Agriculture with IoT Technology
                    </p>
                    <p className="mt-2 paraheading">
                        Rashail Agro allows you to grow more crops, using agrotechnology and IoT. We look forward to empowering farmers and continuing the Government of India's initiative of doubling farmer's income.
                    </p>
                </div>
                <div className="col-md-5 order-lg-2">
                    <img src={aboutLandingimg} alt="rashail-agro-farmers" className="img-fluid" />
                </div>
            </div>
            <div className="row justify-content-center mt-3 ">
                <div className="col-md-10 text-center mx-auto">
                    <div className="row mt-3">
                        <div className="col-md-4  mb-2">
                            <div className="card mx-auto mx-md-2 cart_aboutus" style={{ width: "22rem", height: "280px" }}>
                                <div className="card-body">
                                    <div className="image-overlay text-center" >
                                        <img src={ourmission} alt="rashail-agro-mission" className="img-fluid" style={{ backgroundColor: "#2FB95D" }} />
                                    </div>
                                    <h2 className="card-title text-center aboutgreenpartitle">Our Mission</h2>
                                    <p className="card-text aboutgreenpara mt-md-3">Working side by side with farmers; to help them make their soil richer, their produce sweeter, their harvest larger, and finally, their profit bigger.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  mb-2">
                            <div className="card  mx-auto mx-md-2 cart_aboutus" style={{ width: "22rem", height: "280px" }}>
                                <div className="card-body">
                                    <div className="image-overlay text-center" >
                                        <img src={ourvision} alt="rashail-agro-vision" className="img-fluid" />
                                    </div>
                                    <h2 className="card-title text-center aboutgreenpartitle">Our Vision</h2>
                                    <p className="card-text aboutgreenpara mt-md-3">To look forward to a brighter future, a leafier future; where technology will lead the way to accelerating food production.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                            <div className="card  mx-auto mx-md-2 cart_aboutus" style={{ width: "22rem", height: "280px" }}>
                                <div className="card-body">
                                    <div className="image-overlay text-center" >
                                        <img src={ourgoal} alt="rashail-agro-goal" className="img-fluid" />
                                    </div>
                                    <h2 className="card-title text-center aboutgreenpartitle">Our Goal</h2>
                                    <p className="card-text aboutgreenpara mt-md-3">Stay ahead of the smart agriculture revolution; by grabbing the best of tech and giving it a green reach.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* What Drives Us section ---------------- */}
            <div className="row justify-content-center mx-auto mt-4">
                <div className="col-md-5">
                    <p className="mt-3 aboutusparaheading">
                        What Drives Us?
                    </p>
                    <div className="mt-2 paraheading">
                        <p>
                            At Rashail Agro, we believe that modern technology can transform the very foundation of nature.
                        </p>
                        <p>
                            Powered by hopes of a greener, more lusher earth, we are committed to harnessing the capability of IoT to revamp farming. We aspire to a future in which each seed blossoms not just into a harvest but into a heritage of riches and growth.
                        </p>
                    </div>
                </div>
                <div className="col-md-5">
                    {/* <div className="row" style={{ position: "relative" }}>
                        <div style={{ position: "absolute" }}>
                            <img src={drive1} alt="rashail-agro-farmer1" className="img-fluid drive1_img mt-md-5" width={500} height={283} />
                        </div>
                        <div className='drive2_img_move' style={{ position: "absolute", bottom: -420, right: -270 }}>
                            <img src={drive2} alt="rashail-agro-farmer-2" className="img-fluid drive2_img" width={333} height={261} />
                        </div>
                        <div className='drive3_img_move' style={{ position: "absolute", bottom: -400, right: -60 }}>
                            <img src={drive3} alt="rashail-agro-text-logo" className="img-fluid drive3_img" width={200} height={60} />
                        </div>
                    </div> */}
                    <img src={aboutdrive} alt="rashail-agro-farmers1" className="img-fluid" />
                </div>
            </div>
            {/* <div className="emptydiv d-md-none d-block" style={{ height: '300px' }}>
            </div> */}
            <div className="row justify-content-center background_aboutmiddle mt-5">
                <div className="col-md-12 ">
                    <div className="row justify-content-center " >
                        <div className='col-md-10 justify-content-center d-flex'>
                            <div className='col-md-4 text-center'>
                                <div className='m_about'> <Odometer
                                    value={odometerValue}

                                />M+</div>
                                <div className='active_about'>Active users</div>
                            </div>
                            <div className='col-md-4 text-center'>
                                <div className='m_about'><Odometer
                                    value={odometerValue1}

                                />k</div>
                                <div className='active_about'>Happy Farmers</div>
                            </div>
                            <div className='col-md-4 text-center'>
                                <div className='m_about'><Odometer
                                    value={odometerValue2}

                                /></div>
                                <div className='active_about'>Team Experts</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonal section ------------------- */}
            <div className="row justify-content-center  mx-auto mt-4 ">
                <div className="col-md-10 ">
                    <h2 className='text-center'><span className='line_heading'></span>Testimonal</h2>
                    <h2 className="text-center mb-4 heading_home">Visit our <span className='heading_home_color'>User Reviews</span></h2>
                    <div className="carousel-container">
                        <div className="d-flex flex-column">
                            <div className="carousel-items">
                                {/* Render 5 items on the screen */}
                                {Array(5)
                                    .fill()
                                    .map((_, index) => {
                                        const imageItem =
                                            images[
                                            getCircularIndex(currentIndex + index - 2, images.length)
                                            ];
                                        return (
                                            <div
                                                key={index}
                                                className={`${index === 2 ? "center" : "opacity"} ${(index === 0 || index === 4) &&
                                                    "end d-none d-md-block d-lg-block"
                                                    }
                                                 ${(index === 1 || index === 3) &&
                                                    "d-none d-md-block d-lg-block"
                                                    }`}
                                                onClick={() =>
                                                    handleItemClick(
                                                        getCircularIndex(currentIndex + index - 2, images.length)
                                                    )
                                                }
                                            >
                                                <img src={imageItem.img} alt="rashail-agro-testimonial" className='caroselimge' />
                                            </div>
                                        );
                                    })}
                            </div>
                            <div>
                                <div className="description">
                                    <p className='text-center paraheading'>{images[currentIndex].name}</p>
                                    <p className='text-center'>
                                        {Array.from({ length: 5 }).map((_, starIndex) => {
                                            const isFilled = starIndex < Math.floor(images[currentIndex].rating);
                                            const isHalfFilled = starIndex === Math.floor(images[currentIndex].rating) && images[currentIndex].rating % 1 !== 0;

                                            return (
                                                <span
                                                    key={starIndex}
                                                    className={`fa fa-star${isFilled ? " checked" : ""}`}
                                                    style={{
                                                        fontSize: "30px",
                                                        backgroundImage: isHalfFilled ? "linear-gradient(to right, green 50%, black 50%)" : "none",
                                                        WebkitBackgroundClip: isHalfFilled ? "text" : "none",
                                                        color: isHalfFilled ? "transparent" : (isFilled ? "green" : "inherit"),
                                                    }}
                                                ></span>
                                            );
                                        })}
                                    </p>
                                    <p className='text-center paraheading'>{images[currentIndex].desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: '200px' }}>
            </div>
        </div >
    )
}

export default Aboutus
