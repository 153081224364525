import React, { useState } from 'react'
import features1 from '../Images/Features/iPhone 13 Pro.webp'
import features2 from '../Images/Features/features2.webp'
import features3 from '../Images/Features/feature3.webp'
import features4 from '../Images/Features/features4.webp'
import features5 from '../Images/Features/features5.webp'
import explore from '../Images/Home/Group 1000007771.webp'
import { Card, Modal, Button } from 'react-bootstrap';
import iosqr from '../Images/Home/iosqr.webp'
import modelimg from '../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp'
import { Helmet } from 'react-helmet'
import { Title, Meta, HeadProvider, Link } from "react-head";
import qrforandroid from '../Images/Home/qr_forandroid.png'


const Features = () => {
    const [showModal1, setShowModal1] = useState(false);

    const handleModalClose1 = () => {
        setShowModal1(false);
        // setShowApplicationForm(false);
    };

    const handleModalOpen1 = () => {
        setShowModal1(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };

    const handleDownloadClick = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };

    const handleDownloadClick_android = () => {
        window.open('https://play.google.com/store/apps/details?id=com.rashailagro.fasalam', '_blank');
    };

    return (
        <div className='container-fluid'>
            {/* <Helmet>
                <title>Fasalam App: Your All-in-One Smart Farming Tool</title>
                <meta name="description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <base target="_blank" href="https://rashailagro.in/features/" />
                <link rel="canonical" href="https://rashailagro.in/features/" />
                <meta property="og:title" content="Fasalam App: Your All-in-One Smart Farming Tool" />
                <meta property="og:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <meta property="og:url" content="https://rashailagro.in/features/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Fasalam App: Your All-in-One Smart Farming Tool" />
                <meta name="twitter:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <meta property="twitter:url" content="https://rashailagro.in/features/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>Fasalam Farming App: Your All-in-One Smart Farming Tool</Title>
                <Meta name="description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro." />
                <base target="_blank" href="https://rashailagro.in/features/" />
                <Link rel="canonical" content="https://rashailagro.in/features/" />
                <Meta property="og:title" content="Fasalam Farming App: Your All-in-One Smart Farming Tool" />
                <Meta property="og:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro." />
                <Meta property="og:url" content="https://rashailagro.in/features/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Fasalam Farming App: Your All-in-One Smart Farming Tool" />
                <Meta name="twitter:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro." />
                <Meta property="twitter:url" content="https://rashailagro.in/features/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row Termconditionbackground d-flex align-items-center">
                <div className="col-md-11 col-sm-12 text-center mx-auto">
                    <h1 className='mt-5 '><span className='policy_heading'> Fasalam </span><span className='privacy_heading '> Your Farming App  </span></h1>
                    <h2 className='text-center border_new'>Your 360° Farming App; incorporating the Best of Agricultural Technology & Advanced Features</h2>
                </div>
            </div>
            <div className="row middle_down_farming justify-content-center text-center mt-5 mb-5 position-relative">
                {/* <div style={{ position: 'absolute', top: '-30px', left: '0' }} >
                    <label className="switch" onMouseEnter={handleHoverToggle} onMouseLeave={handleMouseLeaveToggle}>
                        <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
                        <span className="slider round"></span>
                    </label>
                </div> */}
                <div className="col-md-5 ">
                    <h2 className='mt-3 text-center text-md-start download_farming_home'>Download Your Fasalam Farming App now!</h2>
                    <p className='text-center text-md-start enhance_home'>
                        Enhance your farming experience and boost productivity - download your Fasalam Farming App now!
                    </p>
                </div>
                <div className="col-md-5 d-flex ">
                    <div className="card mx-2" style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                        <div className="card-body">
                            <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For iOS</h3>
                            <button className="btn buttonfunctionaity_farming btn-blocks mb-2" onClick={handleDownloadClick}>Download app</button>
                            <div className='mt-md-4 mx-md-3 text-start'>
                                <img src={iosqr} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                            </div>
                            <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                    <g transform="translate(30, 30)">
                                        <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1747 7.79248C28.7402 5.77168 29.9269 2.91548 29.4977 0C26.9391 0.177808 23.9485 1.81479 22.2035 3.94849C20.6128 5.88179 19.3054 8.75777 19.816 11.5491C22.6131 11.6366 25.4999 9.96004 27.1747 7.79248ZM41 35.2172C39.8806 37.7122 39.342 38.827 37.9 41.0369C35.8885 44.1217 33.0522 47.963 29.5341 47.9912C26.4117 48.0251 25.6065 45.945 21.3674 45.9704C17.1284 45.993 16.2447 48.0307 13.1166 47.9996C9.60139 47.9686 6.91377 44.5027 4.90226 41.4179C-0.725479 32.7985 -1.31743 22.6804 2.15291 17.2982C4.62171 13.4767 8.51568 11.2414 12.174 11.2414C15.8968 11.2414 18.2394 13.2961 21.3226 13.2961C24.3132 13.2961 26.1339 11.2358 30.4403 11.2358C33.7002 11.2358 37.1537 13.0223 39.6113 16.1043C31.554 20.5467 32.8586 32.1211 41 35.2172Z" fill="#FBFBFB" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="card mx-2 " style={{ width: "18rem", position: "relative", borderRadius: "15px" }}>
                        <div className="card-body">
                            <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">For Android</h3>
                            <button className="btn buttonfunctionaity_farming btn-blocks mb-2"
                                onClick={handleDownloadClick_android} >Download app</button>
                            <div className='mt-md-4 mx-md-3 text-start '>
                            <img src={qrforandroid} alt="fasalam-mobile-app" className="img-fluid" width={70} height={70} />
                            </div>
                            <div className="svg-container" style={{ position: "absolute", bottom: -16, right: -16 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                                    <g transform="translate(30, 30)">
                                        <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                                        <g clip-path="url(#clip0_1617_5839)">
                                            <path d="M6.66272 15.548C5.83593 15.548 5.13341 15.8362 4.55669 16.4135C3.97997 16.9905 3.69141 17.6827 3.69141 18.4903V30.8949C3.69141 31.7213 3.97987 32.4232 4.55669 33.0002C5.13341 33.5773 5.83552 33.8653 6.66241 33.8653C7.48951 33.8653 8.18657 33.5772 8.75379 33.0002C9.32142 32.4232 9.60503 31.7213 9.60503 30.8949V18.4903C9.60503 17.6824 9.31637 16.9902 8.73965 16.4135C8.16282 15.8365 7.47021 15.548 6.66272 15.548Z" fill="#FBFBFB" />
                                            <path d="M10.7598 35.3083C10.7598 36.1927 11.0675 36.9427 11.6828 37.5583C12.2983 38.1731 13.0481 38.4807 13.9329 38.4807H16.0677L16.0968 45.029C16.0968 45.8561 16.3852 46.5576 16.9621 47.1347C17.5388 47.7117 18.2313 48.0001 19.0389 48.0001C19.8662 48.0001 20.5679 47.7117 21.1449 47.1347C21.7219 46.5576 22.0102 45.8561 22.0102 45.029V38.4807H25.9908V45.029C25.9908 45.8561 26.2791 46.5576 26.8559 47.1347C27.4333 47.7117 28.1352 48.0001 28.9623 48.0001C29.7887 48.0001 30.4906 47.7117 31.0673 47.1347C31.644 46.5576 31.9321 45.8561 31.9321 45.029V38.4807H34.0959C34.9608 38.4807 35.7014 38.1737 36.3167 37.5583C36.9322 36.9427 37.24 36.1927 37.24 35.3083V16.0961H10.7598V35.3083Z" fill="#FBFBFB" />
                                            <path d="M30.5477 4.41346L32.5953 0.634356C32.7302 0.384389 32.6817 0.192115 32.4514 0.057634C32.201 -0.0578519 32.0088 -0.000159481 31.8747 0.230812L29.7973 4.03841C27.9704 3.23072 26.0382 2.82687 23.9994 2.82687C21.9611 2.82687 20.0282 3.23072 18.2012 4.03841L16.1242 0.230913C15.9901 -5.84507e-05 15.7975 -0.0577509 15.5475 0.057735C15.3166 0.192216 15.2689 0.38449 15.4034 0.634457L17.4516 4.41346C15.3743 5.47102 13.721 6.94708 12.4898 8.84133C11.2591 10.7357 10.6436 12.808 10.6436 15.0577H37.3271C37.3271 12.808 36.7116 10.7356 35.4808 8.84133C34.2495 6.94708 32.6061 5.47092 30.5477 4.41346ZM18.706 9.90849C18.4851 10.1298 18.2208 10.2403 17.913 10.2403C17.6052 10.2403 17.3458 10.1299 17.134 9.90849C16.9227 9.68752 16.8167 9.42301 16.8167 9.11535C16.8167 8.80759 16.9227 8.54307 17.134 8.3218C17.3455 8.10052 17.6053 7.99019 17.913 7.99019C18.2204 7.99019 18.4848 8.10042 18.706 8.3218C18.9273 8.54307 19.0379 8.80759 19.0379 9.11535C19.0379 9.42311 18.9273 9.68722 18.706 9.90849ZM30.8654 9.90849C30.6534 10.1298 30.3938 10.2403 30.0861 10.2403C29.7783 10.2403 29.5145 10.1299 29.2932 9.90849C29.0719 9.68752 28.9617 9.42301 28.9617 9.11535C28.9617 8.80759 29.0719 8.54307 29.2932 8.3218C29.5144 8.10052 29.7783 7.99019 30.0861 7.99019C30.3938 7.99019 30.6534 8.10042 30.8654 8.3218C31.0764 8.54307 31.1826 8.80759 31.1826 9.11535C31.1826 9.42311 31.0767 9.68722 30.8654 9.90849Z" fill="#FBFBFB" />
                                            <path d="M43.4424 16.3989C42.8656 15.8317 42.1637 15.548 41.3366 15.548C40.5293 15.548 39.8369 15.8317 39.2602 16.3989C38.6832 16.9663 38.3945 17.6636 38.3945 18.4903V30.8949C38.3945 31.7213 38.6832 32.4232 39.2602 33.0002C39.8369 33.5773 40.5293 33.8653 41.3366 33.8653C42.1637 33.8653 42.8656 33.5772 43.4424 33.0002C44.0198 32.4232 44.3081 31.7213 44.3081 30.8949V18.4903C44.3081 17.6635 44.0198 16.9662 43.4424 16.3989Z" fill="#FBFBFB" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row  d-flex  justify-content-center mx-auto align-items-center">

                <div className="col-md-5  col-5  ">
                    <h3 className="mt-3 featuresparaheading">
                        Intuitive Design &gt;
                    </h3>
                    <p className="mt-2 paraheading">
                        The beautifully simple design of the Fasalam farming app makes it a pleasure to navigate and manage your connected devices. All from a single, clever dashboard.
                    </p>
                </div>
                <div className="col-md-5 col-5 backgroundfeaturs_image d-flex justify-content-center align-items-end">
                    <img src={features1} alt="Fasalam_farming_app_design" className="img-fluid" />
                </div>
            </div>

            <div className="row d-flex justify-content-center mx-auto align-items-center">
                <div className="col-md-5  col-5 backgroundfeaturs_image d-flex justify-content-center align-items-end">
                    <img src={features2} alt="Fasalam_farming_app_plant_diagnosis" className="img-fluid " />
                </div>
                <div className="col-md-5 col-5 ">
                    <h3 className="mt-3 featuresparaheading">
                        &lt; Built-in Plant Diagnosis
                    </h3>
                    <p className="mt-2 paraheading">
                        Check out the power of the Fasalam’s built-in plant diagnosis – instantly recognize and respond to any issues such as nutrient deficiencies and the presence of pests to ensure your green companions thrive and better produce for you.
                    </p>
                </div>
            </div>
            <div className="row d-flex justify-content-center mx-auto align-items-center">
                <div className="col-md-5 col-5 ">
                    <h3 className="mt-3 featuresparaheading">
                        IoT Device Controls &gt;
                    </h3>
                    <p className="mt-2 paraheading">
                        Real-time farm monitoring. Our Farm IoT Device never sleeps, online 24/7 pulling in key agro-data and alerted in bad weather. And you can control your devices with the Fasalam farming app – no matter where you are.
                    </p>
                </div>
                <div className="col-md-5 col-5 backgroundfeaturs_image d-flex justify-content-center align-items-end">
                    <img src={features3} alt="Fasalam_farming_app_iot_device_controls" className="img-fluid " />
                </div>
            </div>
            <div className="row d-flex justify-content-center mx-auto align-items-center">
                <div className="col-md-5 col-5  backgroundfeaturs_image d-flex justify-content-center align-items-end">
                    <img src={features4} alt="Fasalam_farming_app_mandi_rates_updates" className="img-fluid " />
                </div>
                <div className="col-md-5 col-5">
                    <h3 className="mt-3 featuresparaheading">
                        &lt; Explore Mandi Rates
                    </h3>
                    <p className="mt-2 paraheading">
                        Check out the latest mandi rates conveniently from your smartphone with the Fasalam farming app. Stay abreast of the market trends and pricing fluctuations in real-time with Fasalam. It keeps you linked with mandi and local trade.
                    </p>
                </div>
            </div>
            <div className="row d-flex justify-content-center mx-auto align-items-center">
                <div className="col-md-5 col-5 ">
                    <h3 className="mt-3 featuresparaheading">
                        Connect with Like-minded Farmers &gt;
                    </h3>
                    <p className="mt-2 paraheading">
                        Find a community of fellow smart farmers. With Fasalam, it's simple to mingle with those who share your zeal for better farming. Meet up with people who understand the difficulties and triumphs of raising crops.
                    </p>
                </div>
                <div className="col-md-5  col-5  backgroundfeaturs_image d-flex justify-content-center align-items-end">
                    <img src={features5} alt="fasalam-community-mockup" className="img-fluid " />
                </div>
            </div>


            <div className="row mt-5 mb-2 justify-content-center">
                <div class=" text-center">
                    <h3 className='heading_home'>
                        Explore the  <span className='heading_home_color'>merits of our app</span>
                    </h3>
                    <div className='mt-5 fasalam_bottombackground' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='col-md-8'>
                            <img src={explore} alt="Fasalam_farming_app_community" className="img-fluid py-5" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="emptydiv" style={{ height: '200px' }}>
            </div>
            <Modal show={showModal1} onHide={handleModalClose1}
            //  scrollable
            >
                <Modal.Body className="text-center">
                    <img src={modelimg} alt="Landingimg" className="img-fluid" style={{ width: '80%', height: '80%' }} />
                    <h4 className='comingsoon_model'>Coming Soon!</h4>
                    <p className='paramodel'>Stay tuned for the launch of our Fasalam App, where innovation meets cultivation!</p>

                    <button className='buttonfunctionaity_model' onClick={handleModalClose1}>
                        Back to Home
                    </button>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default Features
