import React, { useEffect, useState } from 'react';
import loading_image from '../Images/blog/loader_img.gif';

const Downloadfasalam = () => {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        if (!loading) {
            window.location.href = 'https://preprod.dashboard.rashailagro.in/';
        }
    }, [loading]);

    return (
        <div>
            {loading ? (
                <div className="loading-container">
                    <img src={loading_image} alt="Loading" className="loading-image" width={200} height={200} />
                </div>
            ) : null}
            <div className="emptydiv" style={{ height: '250px' }}></div>
        </div>
    );
};

export default Downloadfasalam;
