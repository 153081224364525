import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../Images/Navbar/Rashail Agro LOGO (1).png'
import { Card, Modal, Button } from 'react-bootstrap';
import modelimg from '../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { List, ListItem, ListItemText } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import closed_img from '../Images/Navbar/menu-alt-05-svgrepo-com 1.webp'
import menu from '../Images/Navbar/Menu.webp'
import iosqr from '../Images/Home/iosqr.webp'
import Demoform from './Demoform';
import { useSelector } from 'react-redux';
import { selectTotalCount } from '../Redux/cartSlice';
import { enquiryurl } from '../Constants/urls';
import Demoformhome from './Demoformhome';


function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [activeDrawerLink, setActiveDrawerLink] = useState(location.pathname);
    const products = useSelector((state) => state.cart);
    const cartItemCount = products.length;
    const totalCount = useSelector(selectTotalCount);

    console.log(products)
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const handleModalOpen = () => {
        setShowModal(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };

    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };

    const handleApplyNowClick = () => {
        // Set both modals to open
        setShowModal(true);
        setShowApplicationForm(true);
    };

    const isRouteActive = (path) => {
        return location.pathname === path;
    };

    const getLinkClass = (path) => {
        return isRouteActive(path) ? 'gradient-text-navbar' : 'nav-link font-weight-bold';

    };

    const getHoverClass = () => {
        return 'hover-effect';
    };

    const handleLinkClick = () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        if (navbarToggler) {
            navbarToggler.click();
        }
        window.dataLayer.push({ 'event': 'button-click' });
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
        if (open) {
            navigate(activeDrawerLink);
        }
        window.dataLayer.push({ 'event': 'button-click' });
    };


    const isMobile = useMediaQuery('(max-width: 768px)');
    const drawerWidth = 250;

    const handleDownloadClick = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };

    // const [productName, setproductName] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: null,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key] && formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });
        

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const token = 'e606743946ca17935cdaf8074a5a6bfce1674f2b';
            const response = await fetch(enquiryurl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid mx-auto">
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            {/* <MenuIcon /> */}
                            <img src={closed_img} alt="closed_img" className='closed_img' />
                        </IconButton>
                    )}
                    <Link className="navbar-brand mx-md-5" to="/" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                        {/* Uncomment the following line and replace 'Logo' with your actual logo image */}
                        <img src={Logo} alt="rashail_agro_logo" className='navbar_logo' width={200} />
                    </Link>
                    <div className=' d-lg-none  getapp getapp_text mx-md-5 text-start' onClick={handleModalOpen}>
                        <span className=''>Request a Demo</span>
                    </div>
                    {/* <div>
                    <span className='rashaillogotext'>Rashail</span>
                    <span className='rashaillogotext2 mx-2'>Agro</span>
                     </div> */}


                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav mx-auto text-start" style={{ position: "relative" }}>
                            <Link onClick={handleLinkClick}
                                className={`${getLinkClass('/')} ${getHoverClass()}`}
                                to="/">
                                Home
                            </Link>
                            <span style={{ margin: '0 10px' }}></span>
                            <Link onClick={handleLinkClick}
                                className={`${getLinkClass('/features/')} ${getHoverClass()}`}
                                to="/features/">
                                Fasalam
                            </Link>
                            <span style={{ margin: '0 10px' }}></span>
                            <Link onClick={handleLinkClick}
                                className={`${getLinkClass('/shop/')} ${getHoverClass()}`}
                                to="/shop/">
                                Products
                            </Link>
                            <span style={{ margin: '0 10px' }}></span>
                            <Link onClick={handleLinkClick}
                                className={`${getLinkClass('/about-us/')} ${getHoverClass()}`}
                                to="/about-us/">
                                About us
                            </Link>
                            <span style={{ margin: '0 10px' }}></span>
                            <Link
                                onClick={handleLinkClick}
                                className={`${getLinkClass('/cart/')} ${getHoverClass()}`}
                                to="/cart/">
                                Cart
                                {cartItemCount > 0 && <span className="cart-count position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                                    {totalCount}
                                </span>}
                            </Link>
                        </div>
                        <div className='getapp getapp_text mx-5 text-start' onClick={handleModalOpen}>
                            <span className=''>Request a Demo</span>
                        </div>
                    </div>
                </div>
            </nav>
            <Modal show={showModal} onHide={handleModalClose}
            //  scrollable
            >
                <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                    {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="col-md-10 col-sm-10 text-center mx-auto">
                        <Demoformhome onSubmit={handleSubmit} />
                    </div>
                </Modal.Body>
            </Modal>
            <SwipeableDrawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                PaperProps={{ style: { width: '100%' } }}
            >

                <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    style={{ position: 'fixed', right: 20, top: 20, zIndex: 1 }}
                    onClick={() => {
                        toggleDrawer(false)();
                        setActiveDrawerLink('/');
                        console.log('Clicked CloseIcon');
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* ... (previous code) */}
                <div className='container-fluid'>
                    <div className='col-md-12 d-flex justify-content-between align-items-center'>
                        <div>
                            <List >
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/');
                                    }}
                                    component={Link}
                                    to="/"
                                    className={`drawer-link ${activeDrawerLink === '/' ? 'active-drawer-link' : ''}`}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ListItemText primary="Home" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/features/');
                                    }}
                                    component={Link}
                                    to="/features/"
                                    className={activeDrawerLink === '/features/' ? 'active-drawer-link' : ''}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ListItemText primary="Fasalam" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/shop/');
                                    }}
                                    component={Link}
                                    to="/shop/"
                                    className={activeDrawerLink === '/shop/' ? 'active-drawer-link' : ''}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ListItemText primary="Products" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/about-us/');
                                    }}
                                    component={Link}
                                    to="/about-us/"
                                    className={activeDrawerLink === '/about-us/' ? 'active-drawer-link' : ''}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ListItemText primary="About us" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/cart/');
                                    }}
                                    component={Link}
                                    to="/cart/"
                                    className={activeDrawerLink === '/cart/' ? 'active-drawer-link' : ''}
                                    style={{ marginBottom: '10px' }}
                                >
                                     {cartItemCount > 0 && <span className="cart-count position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                                        {totalCount}
                                    </span>}
                                    <ListItemText primary="Cart" />
                                </ListItem>


                                {/* Add more list items as needed */}
                            </List>
                        </div>
                        <div>
                            <div className='mt-5'>
                                <img src={menu} alt="menu" className='menu mt-5' />
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    );
}

export default Navbar;
