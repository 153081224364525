import React from 'react'
import { Helmet } from 'react-helmet'
import { Title, Meta, HeadProvider, Link } from "react-head";




const Termscondition = () => {
    return (
        <div className='container-fluid'>
            {/* <Helmet>
                <title>Rashail Agro Terms and Conditions</title>
                <meta name="description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <base target="_blank" href="https://rashailagro.in/terms-condition/" />
                <link rel="canonical" href="https://rashailagro.in/terms-condition/" />
                <meta name="keywords" content="rashail agro, user data, farmers data" />
                <meta property="og:title" content="Rashail Agro Terms and Conditions" />
                <meta property="og:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <meta property="og:url" content="https://rashailagro.in/terms-condition/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Terms and Conditions" />
                <meta name="twitter:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <meta property="twitter:url" content="https://rashailagro.in/terms-condition/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>Rashail Agro Terms and Conditions</Title>
                <Meta name="description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <base target="_blank" href="https://rashailagro.in/terms-condition/" />
                <Link rel="canonical" content="https://rashailagro.in/terms-condition/" />
                <Meta name="keywords" content="rashail agro, user data, farmers data" />
                <Meta property="og:title" content="Rashail Agro Terms and Conditions" />
                <Meta property="og:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <Meta property="og:url" content="https://rashailagro.in/terms-condition/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Rashail Agro Terms and Conditions" />
                <Meta name="twitter:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <Meta property="twitter:url" content="https://rashailagro.in/terms-condition/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row Termconditionbackground d-flex align-items-center">
                <div className="col-md-11 col-sm-12  mx-auto">
                    <h1 className='mt-5 '><span className='privacy_heading'>Terms</span><span className='policy_heading'> & Conditions</span></h1>
                    <p className='text-start border_new' >Last Updated Jan 24, 2024</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-11 col-sm-12 text-center mx-auto">
                    <p className='paraheading_terms text-start mt-4 mb-5'>
                        Acceptance of Terms By accessing and using the services provided by Rashail Infotech and its website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please refrain from using our services.
                    </p>
                    <span>
                        <h2 className='mainheading_terms text-start'>1.Use of Services</h2>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>You may use our website and services only for lawful purposes.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>You agree not to engage in any activities that may harm the security, integrity, or availability of our website and services.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span> You must be of legal age or have appropriate parental or guardian consent to use our services.
                        </p>
                    </span>
                    <span>

                        <h2 className='mainheading_terms text-start'>2. Privacy and Data Collection</h2>

                        <p className='paraheading_terms mt-4 mb-5 text-start'>
                            Our Privacy Policy governs the collection and use of personal information provided by users. By using our services, you consent to the practices outlined in our Privacy Policy.
                        </p>
                    </span>
                    <span>

                        <h2 className='mainheading_terms text-start'>3.Intellectual Property</h2>

                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>All content, trademarks, logos, and materials on our website are protected by copyright and other intellectual property laws.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>You may not use our content without our explicit written permission.
                        </p>
                    </span>
                    <span>

                        <h2 className='mainheading_terms text-start'>4.Third-Party Links</h2>

                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>Our website may contain links to third-party websites. We do not endorse or control these third-party sites and are not responsible for their content or actions.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>You access third-party sites at your own risk, and you should review their terms and privacy policies.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>5.Disclaimers</h2>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>The Company provides information and services on an "as is" and "as available" basis without warranties of any kind, either expressed or implied.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>We do not guarantee the accuracy, completeness, or reliability of the information or services provided.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>6.Limitation of Liability</h2>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>To the fullest extent permitted by applicable law, the Company and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
                        </p>
                        <p className='paraheading_terms text-start'>
                            <span className="checkbox"></span>The total liability of the Company for any claim arising out of or relating to these Terms and Conditions or the use of our services shall not exceed the amount you paid for those services.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>7.Termination</h2>
                        <p className='paraheading_terms mt-4 mb-5 text-start'>
                            The Company reserves the right to terminate or suspend your access to our services at its discretion, with or without cause. Termination may result from a violation of these Terms and Conditions or any other policies governing our services.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>8.Governing Law & Jurisdiction</h2>
                        <p className='paraheading_terms mt-4 mb-5 text-start'>
                            These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes shall be resolved in the courts of India.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>9.Changes to Terms and Conditions</h2>
                        <p className='paraheading_terms mt-4 mb-5 text-start'>
                            The Company may update these Terms and Conditions from time to time. We will notify users of any significant changes.
                        </p>
                    </span>
                    <span>
                        <h2 className='mainheading_terms text-start'>10.Contact Us</h2>
                        <p className='paraheading_terms mt-4 mb-5 text-start'>
                            If you’d like to learn more about our privacy policies, we’d be happy to
                            provide you with more information on how we protect and use your
                            personal information. Contact Support for more information.

                        </p>
                    </span>
                </div>
            </div>
            <div className="emptydiv" style={{ height: '150px' }}>
            </div>
        </div>
    )
}

export default Termscondition
