import React, { useEffect } from 'react'
import gsmmotor from '../Images/Home/gsm motor.png'
import { Helmet } from 'react-helmet'
import Demoform from '../Components/Demoform';
import { Card, Modal, Button } from 'react-bootstrap';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';
import Iotproduct from '../Components/Iotproduct';
import { Title, Meta, HeadProvider, Link } from "react-head";
import Buynowform from '../Components/Buynowform';
import { Prices, Shipping } from '../Constants/Homedata';
import { useLocation } from 'react-router-dom';
import ReactImageZoom from 'react-image-zoom';
import axios from 'axios';
import { producturl } from '../Constants/urls';
import { add, remove } from '../Redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import ReactImageMagnify from 'react-image-magnify';



const Gsmmotorcontroller = () => {
    // Data
    const location = useLocation();
    const url = window.location.href;
    const url1 = window.location.origin;
    const parturl = url.replace(url1, "").replace("/shop/", "");
    const slug = parturl.endsWith("/") ? parturl.replace("/", "") : parturl;
    console.log(slug)
    const [showModal, setShowModal] = useState(false);
    const products = useSelector((state) => state.cart);

    const [product, setproduct] = useState();
    const dispatch = useDispatch();
    console.log(product)
    const [showModal1, setShowModal1] = useState(false);
    const [productName, setProductName] = useState(product?.product_name || '');
    const [Price, setPrice] = useState(product?.product_price || 0);

    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalOpen1 = () => {
        setShowModal1(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };
    const handleModalClose1 = () => {
        setShowModal1(false);
    };


    const handleAdd = (product) => {
        dispatch(add(product));
    };

    const handleRemove = (product_id) => {
        dispatch(remove(product_id));
    };

    const isInCart = (productId) => {
        return products.some(item => item.product_id === productId);

    };

    const fetchData = async (page = 1) => {
        try {
            //   setLoading(true);
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(
                `${producturl}?slug=${slug}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            console.log(response);
            setproduct(response.data.results[0]);
        } catch (error) {
            console.error(error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const schema = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "Rashail - GSM Motor Controller 2G/4G",
        "image": "https://rashailagro.in/static/media/gsm%20motor.3af9bc88abf6e425f1b1.png",
        "description": "Introducing Rashail Agro&#39;s<STRONG> GSM Motor Controller</STRONG> 2G/4G – the ultimate solution for motor control in irrigation and submersible applications. Say goodbye to manual and hello to technology; This <STRONG>mobile pump controller </STRONG> gives you the power to manage your motors from anywhere in the world, via your mobile phone.<BR/>But it doesn&#39;t stop there. Our<STRONG> GSM Motor Controller</STRONG>  goes the extra mile with security features too. Protect your motors from coil burning with advanced Phase Fault and Dry Run Prevention Features.<BR/>Increase the life of your motors and add convenience, peace of mind to the mix. Rashail Agro&#39;s<STRONG>  GSM Motor Controller</STRONG> is a worthy investment and your gateway to smart farming.",
        "offers": {
            "@type": "Offer",
            "price": 3999.00,
            "priceCurrency": "INR"
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);

    console.log(selectedImage)
    const handleThumbnailHover = (image) => {
        setSelectedImage(image);
    };


    const [technicalDetails, setTechnicalDetails] = useState([]);
    const [additionalDetails, setAdditionalDetails] = useState([]);

    useEffect(() => {
        // Fetch and set technical details from API response
        if (product && product.extra_data && product.extra_data.technical_details) {
            setTechnicalDetails(product.extra_data.technical_details);
        }
        if (product && product.extra_data && product.extra_data.additional_details) {
            setAdditionalDetails(product.extra_data.additional_details);
        }
    }, [product]);

    useEffect(() => {
        if (product && product.product_images.length > 0) {
            setSelectedImage(product.product_images[0]);
        }
        if (product) {
            setProductName(product.product_name); // Set productName state
            setPrice(product.product_price); // Set Price state
        }
    }, [product]);

    const [smallImageDimensions, setSmallImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = selectedImage;
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const maxWidth = 400;
            const maxHeight = 400;
            let width = maxWidth;
            let height = maxHeight;

            if (aspectRatio > maxWidth / maxHeight) {
                height = maxWidth / aspectRatio;
            } else {
                width = maxHeight * aspectRatio;
            }

            setSmallImageDimensions({ width, height });
        };
    }, [selectedImage]);


    return (
        <div className='container-fluid'>
            {/* <Helmet>
                <title>Rashail's Mobile Starter: Automation for Efficient Farming</title>
                <meta name="description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <base target="_blank" href="https://rashailagro.in/products/gsm-motor-controller/" />
                <link rel="canonical" href="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta name="keywords" content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, gsm based motor control, gsm mobile starter, mobile starter for 3 phase motor, gsm controlled motor, gsm motor controller, mobile auto switch" />
                <meta property="og:title" content="Rashail's Mobile Starter: Automation for Efficient Farming" />
                <meta property="og:description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <meta property="og:url" content="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail's Mobile Starter: Automation for Efficient Farming" />
                <meta name="twitter:description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <meta property="twitter:url" content="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>{product?.meta_title}</Title>
                <Meta name="description" content={product?.meta_header} />
                <base target="_blank" href={`https://rashailagro.in/shop/${slug}/`} />
                <Link rel="canonical" content={`https://rashailagro.in/shop/${slug}/`}/>
                <Meta name="keywords" content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, gsm based motor control, gsm mobile starter, mobile starter for 3 phase motor, gsm controlled motor, gsm motor controller, mobile auto switch" />
                <Meta property="og:title" content={product?.meta_title} />
                <Meta property="og:description" content={product?.meta_header} />
                <Meta property="og:url" content={`https://rashailagro.in/shop/${slug}/`} />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content={product?.meta_title} />
                <Meta name="twitter:description" content={product?.meta_header} />
                <Meta property="twitter:url" content={`https://rashailagro.in/shop/${slug}/`} />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row mt-5 justify-content-center">
                <div className='col-md-12 col-lg-6 d-flex justify-content-center'>
                    <div className="col-md-12 productimg-productdetails col-lg-6 text-center" >
                        <div className='text-center selectedimage_resp'style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '400px' }} >

                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: 'Wristwatch by Ted Baker London',
                                        className: 'Wristwatch by Ted Baker London',
                                        isFluidWidth: false, // Set to false to fix the width
                                        width: smallImageDimensions.width, // Set the width of the image
                                        height: smallImageDimensions.height,
                                        src: selectedImage,
                                    },
                                    largeImage: {
                                        src: selectedImage,
                                        width: 1200,
                                        height: 1200
                                    },
                                    enlargedImageContainerDimensions: {
                                        width: '200%',
                                        height: '150%',

                                    },
                                    enlargedImageContainerStyle: {
                                        backgroundColor: 'white' // Set the background color of the enlarged image
                                    }
                                }}
                            />
                        </div>
                        <div className="image-container-productdetails d-flex mt-2" style={{ maxWidth: '400px', width: '350px', overflowX: 'scroll', '-ms-overflow-style': 'none' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', }}>
                                {product && product?.product_images.map((image, index) => (
                                    <div key={index} className='mx-2' style={{ minWidth: '120px', maxWidth: '120px' }}>
                                        <img
                                            src={image}
                                            alt={`Thumbnail ${index}`}
                                            className="img-fluid image-thumbnail"
                                            style={{ cursor: 'pointer', width: '100%', height: '70px', objectFit: 'contain' }}
                                            onMouseEnter={() => handleThumbnailHover(image)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start">
                    <div className='text-start'>
                        <div>
                            {product?.is_top_selling ? (
                                <span className='percent_off' style={{ cursor: "pointer" }}>Top selling</span>
                            ) : (
                                product?.on_sale ? (
                                    <span className='percent_off_onsale' style={{ cursor: "pointer" }}>On sale</span>
                                ) : (
                                    ''
                                )
                            )}
                        </div>
                        <h1 className='productdetailsheading text-start mt-2'>{product?.product_name}</h1>
                        <div className='productdetailspara mt-4 mb-3 text-start'>
                            <p className='description_productdetails'>
                                {product?.product_description.split('\n').map((paragraph, index) => (
                                    <React.Fragment key={index}>
                                        {paragraph}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                            {product?.is_enquiry_product ? (
                                <div className="">
                                    <strong style={{ color: "#2FB95D" }}>Demo Product</strong>
                                </div>
                            ) : (
                                <>
                                    {product?.product_discount > 0 && (
                                        <span style={{ color: '#2FB95D' }}>{`${product?.product_discount}% OFF`}</span>
                                    )}
                                    <div>
                                        <span className='fullprice_product'>
                                            <small style={{ textDecoration: "line-through" }}>
                                                ₹
                                                {product?.product_price + (product?.product_price * (product?.product_discount / 100))}
                                            </small>
                                        </span>
                                        <span className='actualprice_product mx-2'>₹{product?.product_price}</span>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="row   justify-content-center">
                <div className='row justify-content-center '>
                    <div className='col-md-10 justify-content-center'>
                        {product?.is_enquiry_product ? (
                            <div
                                className='col-md-3 col-6 readmore_blog text-center custom-span  mx-auto'
                                onClick={handleModalOpen1}
                                style={{ cursor: "pointer" }}
                            >
                                Request a Demo
                            </div>
                        ) : (
                            <>
                                <div className='row justify-content-end'>
                                    <div className='col-md-6 d-flex'>
                                        <div className='col-md-6 col-6 readmore_blog text-center custom-span  mx-auto' onClick={handleModalOpen} style={{ cursor: "pointer" }}>
                                            Buy Now
                                        </div>
                                        <div className='col-md-6 col-6  text-center custom-span  mx-auto' style={{ cursor: "pointer" }}>
                                            {isInCart(product?.product_id) ? (
                                                <button className="btn buttonfunctionaity_productdetails  " onClick={() => handleRemove(product.product_id)}>
                                                    Remove from Cart
                                                </button>
                                            ) : (
                                                <button className="btn buttonfunctionaity_productdetails " onClick={() => handleAdd(product)}>
                                                    Add to Cart
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-md-10 col-sm-10 text-center mt-5">
                    <span>
                        <h2 className='productdetailsheading text-start'>
                            {product?.product_name} Features</h2>
                        <div className='productdetailspara text-start mt-4'>
                            {product?.meta_data ? (
                                <ul>
                                    {product.meta_data.split('\n').map((sentence, index) => (
                                        <li key={index}>{sentence}</li>
                                    ))}
                                </ul>
                            ) : (
                                ''
                            )}
                        </div>

                    </span>
                </div>
                <div className='row justify-content-center mt-3'>
                    <div className='col-md-10 justify-content-center'>
                        {product?.is_enquiry_product ? (
                            <div
                                className='col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto'
                                onClick={handleModalOpen1}
                                style={{ cursor: "pointer" }}
                            >
                                Request a Demo
                            </div>
                        ) : (
                            <div
                                className='col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto'
                                onClick={handleModalOpen}
                                style={{ cursor: "pointer" }}
                            >
                                Buy Now
                            </div>
                        )}
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-md-9 text-center justify-content-center mx-auto">
                        <div className="row text-center mt-3">
                            <div className="col-md-6 mb-2">
                                <div className="card card_iotproductdetails ">
                                    <div className="card-body">
                                        <h3 className="card-title text-start mt-3 productdetailsheading"><center>Technical Details</center></h3>
                                        <div className="d-flex mt-4">
                                            <ul>
                                                {technicalDetails.map((detail, index) => (
                                                    <div className='text-start d-flex mt-2 justify-content-between ' key={index}>
                                                        <span className='text-start col-md-6  technicaldetailshead'>{Object.keys(detail)[0]}</span>
                                                        <span className='text-start col-md-6 technicaldetailssubhead'>{Object.values(detail)[0]}</span>
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <div className="card card_iotproductdetails mx-auto" >
                                    <div className="card-body">
                                        <h3 className="card-title text-start mt-3 productdetailsheading"><center>Additional Info</center></h3>
                                        <div className="d-flex mt-4">
                                            <ul>
                                                {additionalDetails.map((detail, index) => (
                                                    <div className='text-start d-flex mt-2 justify-content-between ' key={index}>
                                                        <span className='text-start col-md-7 technicaldetailshead'>{Object.keys(detail)[0]}</span>
                                                        <span className='text-start col-md-5 technicaldetailssubhead mx-2'>{Object.values(detail)[0]}</span>
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center mt-3'>
                    <div className='col-md-10 justify-content-center'>
                        {product?.is_enquiry_product ? (
                            <div
                                className='col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto'
                                onClick={handleModalOpen1}
                                style={{ cursor: "pointer" }}
                            >
                                Request a Demo
                            </div>
                        ) : (
                            <div
                                className='col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto'
                                onClick={handleModalOpen}
                                style={{ cursor: "pointer" }}
                            >
                                Buy Now
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={showModal} onHide={handleModalClose}
                    //  scrollable
                    dialogClassName="custom-modal" >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        <Modal.Title className="text-center">Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-12 col-sm-12 text-center mx-auto">
                            <div className='mt-3'>
                                <Buynowform productName={productName} Price={Price} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModal1} onHide={handleModalClose1}
                //  scrollable
                >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-10 col-sm-10 text-center mx-auto">
                            <div className='mt-3'>
                                <Demoform productName={productName} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Iotproduct />
                <div className="emptydiv" style={{ height: '200px' }}>
                </div>
            </div >
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
        </div >
    )
}

export default Gsmmotorcontroller
