import React, { useEffect, useRef, useState } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import MapComponent from '../Components/MapComponent ';
import { Title, Meta, HeadProvider, Link } from "react-head";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Contactus = () => {

    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: productName, 
        // description: '',
        is_demo: 'false',
        address: ''
    });

    const notify = (data) => toast(data);
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                notify('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
            } else {
                notify('Error submitting form');
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className='container-fluid'>
            {/* <Helmet>
                <title>Contact Rashail Agro: Your Agricultural Solutions Partner</title>
                <meta name="description" content="Get in touch with Rashail Agro for product inquiries, expert advice, and support for your farming needs." />
                <base target="_blank" href="https://rashailagro.in/contact-us/" />
                <link rel="canonical" href="https://rashailagro.in/contact-us/" />
                <meta name="keywords" content="product installation, farm machinery, purchase farm machinery, near by stores, contact us, customer support, farm advisory, rashail agro " />
                <meta property="og:title" content="Contact Rashail Agro: Your Agricultural Solutions Partner" />
                <meta property="og:description" content="Get in touch with Rashail Agro for product inquiries, expert advice, and support for your farming needs." />
                <meta property="og:url" content="https://rashailagro.in/contact-us/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Contact Rashail Agro: Your Agricultural Solutions Partner" />
                <meta name="twitter:description" content="Get in touch with Rashail Agro for product inquiries, expert advice, and support for your farming needs." />
                <meta property="twitter:url" content="https://rashailagro.in/contact-us/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
            {/* <HeadProvider>
                <Title>Contact Rashail Agro: Your Agricultural Solutions Partner</Title>
                <Meta name="description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <base target="_blank" href="https://rashailagro.in/contact-us/" />
                <Link rel="canonical" content="https://rashailagro.in/contact-us/" />
                <Meta name="keywords" content="product installation, farm machinery, purchase farm machinery, near by stores, contact us, customer support, farm advisory, rashail agro " />
                <Meta property="og:title" content="Contact Rashail Agro: Your Agricultural Solutions Partner" />
                <Meta property="og:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <Meta property="og:url" content="https://rashailagro.in/contact-us/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Contact Rashail Agro: Your Agricultural Solutions Partner" />
                <Meta name="twitter:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <Meta property="twitter:url" content="https://rashailagro.in/contact-us/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div className="row Termconditionbackground d-flex align-items-center">
                <div  className="col-md-11 col-sm-12 text-center mx-auto">
                    <h1 className='mt-5 '><span className='privacy_heading'> Contact</span><span className='policy_heading'>  us </span></h1>
                    <h2 className='text-center border_new'> Get in Touch with Us for a Flourishing Farming IoT Experience!</h2>
                </div>
            </div>
            <div className="row  d-flex align-items-center">
                    <h2 className='mt-5 text-center'><span className='policy_heading'> Any Doubts?</span><span className='privacy_heading'> Feel Free to Contact us! </span></h2>
                <div className="col-md-6 col-sm-6 text-center mx-auto">
                    <div className='mt-5'>
                        <div className="card card_contactus_contactform">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Full Name "
                                                    type="text"
                                                    color="success"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        // style: { fontSize: '16px' },
                                                    }}
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    focused
                                                    InputProps={{
                                                        style: { borderRadius: '20px', borderColor: 'red', },
                                                    }}
                                                    fullWidth
                                                />
                                                {errors.name && <div className="error-message text-end">{errors.name}</div>}
                                            </div>
                                        </Box>
                                    </div>
                                    <div className="mb-4">
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Phone"
                                                    type="number"
                                                    color="success"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    focused
                                                    InputProps={{
                                                        style: { borderRadius: '20px', borderColor: 'red' },
                                                    }}
                                                    fullWidth
                                                />
                                                {errors.phone_number && <div className="error-message text-end">{errors.phone_number}</div>}
                                            </div>
                                        </Box>
                                    </div>
                                    <div className="mb-4">
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    type="email"
                                                    color="success"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    focused
                                                    InputProps={{
                                                        style: { borderRadius: '20px', borderColor: 'red' },
                                                    }}
                                                    fullWidth
                                                />
                                                {errors.email && <div className="error-message text-end">{errors.email}</div>}
                                            </div>
                                        </Box>
                                    </div>
                                    <div className="mb-4">
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextareaAutosize
                                                    id="outlined-number"
                                                    aria-label="Address"
                                                    placeholder="Address.."
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    className='text-area_contact'
                                                    minRows={3}
                                                    color="success"
                                                    focused
                                                    style={{
                                                        borderRadius: '20px', borderColor: 'success', width: '100%',
                                                        maxWidth: '620px', padding: '10px'
                                                    }}
                                                />
                                            </div>
                                        </Box>
                                        {errors.address && <div className="error-message text-end">{errors.address}</div>}
                                    </div>
                                    <div className="mb-4">
                                        <button
                                            type="submit"
                                            className="btn btn-primary submitreview_contactus"
                                            style={{
                                                width: '100%',
                                                maxWidth: '620px'
                                            }}
                                            disabled={loading}>
                                            {loading ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center pb-5 mt-5 mb-5">
                <div className="col-md-3 mb-3">
                    <div className="card card-with-shadow-portfolio card-career" >
                        <div className="card-body portfolio-fixed-card-body d-flex" style={{ overflow: "hidden", width: "100%", height: "100%" }}>
                            <div className='mt-2'>
                                {/* <img src={call} alt="workspace Image" className="img-fluid" /> */}
                                <IoCall
                                    style={{ color: '#2FB95D', fontSize: '36px', marginRight: '20px', cursor: 'pointer' }}
                                />
                            </div>
                            <div className='mx-2'>
                                <div className="card-text contactcardparaheading">Call Us</div>
                                <div className='careercardsubheading'>
                                    <a href="tel:+918602793619" style={{ textDecoration: 'none', color: "#2FB95D" }}>+91 83495 03619</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="card card-with-shadow-portfolio card-career" >
                        <div className="card-body portfolio-fixed-card-body d-flex " style={{ overflow: "hidden", width: "100%", height: "100%" }}>
                            <div className='mt-2'>
                                {/* <img src={message} alt="workspace Image" className="img-fluid mt-3" /> */}
                                <IoMdMail
                                    style={{ color: '#2FB95D', fontSize: '36px', marginRight: '20px', cursor: 'pointer' }}
                                />
                            </div>
                            <div className='mx-2'>
                                <div className="card-text contactcardparaheading">Mail us</div>
                                {/* <p className='careercardsubheading  '>hr@rashailinfotech.com</p> */}
                                <div className='careercardsubheading'>
                                    <a href="tel:+918602793619" style={{ color: "#2FB95D" }}>rashailagro@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6 contactus_map' style={{ width: '50%', height: '400px', position: 'relative', zIndex: 2, }} >
                    <div className='card contactus-card my-3' id='mapcomponent-c' style={{ borderRadius: '30px', overflow: 'hidden', zIndex: 2, }}>
                        <MapComponent />
                    </div>
                    <div className='blockbackgrround-contactus' style={{
                        position: 'absolute',
                        bottom: 8,
                        top: 1,
                        left: 1,
                        width: '150px',
                        height: '150px',
                        borderRadius: "30px",
                        backgroundColor: '#2FB95D',
                        zIndex: -1,
                    }}></div>
                    <div className='socialmediabackground_c' style={{
                        position: 'absolute',
                        top: 60,
                        right: -40,
                        // width: '100px',
                        // height: '100px',

                        zIndex: -1,
                        // pointerEvents: 'none', 
                    }}>
                        <div className='text-end px-3 mt-4'>
                            <a href="https://www.facebook.com/rashailagro/" target="_blank" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }} >
                                <span><i className="fa fa-facebook  mx-2" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', cursor: "pointer" }}></i></span>
                            </a>
                        </div>
                        <div className='text-end px-3 mt-4'>
                            <a href="https://www.instagram.com/rashailagro/" target="_blank" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                <span><i className="fa fa-instagram " aria-hidden="true" style={{ color: '#fff', fontSize: '26px', cursor: "pointer" }}></i></span>
                            </a>
                        </div>
                        <div className='text-end px-3 mt-4'>
                            <a href=" https://twitter.com/RashailAgro" target="_blank" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                <span><i className="fa fa-twitter " aria-hidden="true" style={{ color: '#fff', fontSize: '26px', cursor: "pointer" }}></i></span>
                            </a>
                        </div>
                        <div className='text-end px-3 mt-4'>
                            <a href="https://www.youtube.com/channel/UCiyVvtJtp-MPyYWgDBHXKRw" target="_blank" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                <span><i className="fa fa-youtube-play " aria-hidden="true" style={{ color: '#fff', fontSize: '26px', cursor: "pointer" }}></i></span>
                            </a>
                        </div>
                        <div className='text-end px-3 mt-4' >
                            <a href=" https://www.linkedin.com/showcase/rashail-agro/" target="_blank" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                <span><i className="fa fa-linkedin " aria-hidden="true" style={{ color: '#fff', fontSize: '26px', cursor: "pointer" }}></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: '200px' }}>
            </div>
        </div >
    )
}

export default Contactus
